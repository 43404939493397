const qrPatterns = [
  {
    name: 'Niagara',
    regex: /^([^;]+);([^;]+);(\d+);(\d+);(\d{2}\/\d{2}\/\d{4})$/,
    fields: ['item', 'lot', 'quantity', 'lpn', 'date'],
    keyField: 'lpn',
  },
  {
    name: 'nfc_nxp',
    regex: /^([0-9A-F]{14})x([0-9A-F]{6})x(00000000|FFFFFFFF)$/,
    fields: ['nfc_uid', 'count', 'status'],
    keyField: 'nfc_uid',
  },
]

export default qrPatterns
