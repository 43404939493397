const it = {
  pageTitle: "Scanner Laava",
  pageDescription: "Scansiona la tua Laava Smart Fingerprint™ per assicurarti che il tuo prodotto sia autentico.",
  loading: "Caricamento in corso",
  startingScanner: "Avvio dello scanner",
  privacyPolicy: "Utilizzando Laava, accetti il nostro",
  cookiesPolicy: "Politica sui cookie",
  privacyAndCookies: "Privacy e cookie",
  termsOfUse: "Condizioni d'uso",
  accept: "Accettare",
  copyrightContent:
    "LAAVA, SMART FINGERPRINTS, FINGERPRINTS, AUTENTICAZIONE DELLE COSE, il check Laava, il logo Laava e LAAVA ???? sono marchi di Laava ID Pty Ltd.",
  titleWarningAllowCamera: "Si prega di consentire l'accesso alla telecamera.",
  bodyWarningAllowCamera:
    "Questo scanner web utilizza la fotocamera per eseguire la scansione delle impronte digitali. Consenti l'autorizzazione ad accedere alla videocamera per riprovare.",
  titleWarningSupportedBrowser: "Apri {{browser}} per scansionare la tua impronta digitale.",
  bodyWarningSupportedBrowser: "Il browser che stai utilizzando non è attualmente supportato.",
  activateTheCameraOn: "Attiva la fotocamera su {{device}}.",
  thisWebscannerUsesTheCamera:
    "Questo scanner web utilizza la fotocamera per eseguire la scansione delle impronte digitali.",
  scanNow: "Scannerizza ora",
  refresh: "ricaricare",
  refreshThePage: "Ricarica la pagina",
  loadAgain: "Carica di nuovo scan.laava.id.",
  selectAllowWhenAsked: "Selezionare Consenti quando viene richiesto l'accesso alla telecamera",
  whenAskedSelectAllow: "Quando viene chiesto a scan.laava.id Desidera accedere alla fotocamera, selezionare Consenti.",
  positionTheCode: "Posizionare il codice all'interno della cornice da scansionare",
  moveCloserToQR: "Avvicinati al codice QR",
  moveFurtherFromQR: "Allontanati dal codice QR",
  centerQRCode: "Centra il codice QR",
  unblurQrCode: "Il codice QR è troppo sfocato",
  goodQrCode: "Codice QR valido",
  badQrCode: "Nessun codice QR rilevato",
  qrCodeError: "Errore codice QR",
  seeSupportedBrowsers: "Avvia browser",
  supportedBrowsersPageTitle: "Questo scanner richiede un browser con supporto per fotocamera.",
  supportedBrowsersPageBody:
    "Il sistema operativo del tuo dispositivo determinerà quali browser Web supportano la fotocamera.",
  howUnblockCamera: "Come sbloccare la fotocamera",
  help: "Aiuto",
  supportedBrowsersPageHelpBody: "Se hai domande o dubbi, ti preghiamo di contattarci.",
  contactUs: "Contattaci",
  goToBrowserSettings: "Vai alle impostazioni del browser",
  findSettingBrowser: "Puoi trovare le impostazioni toccando i tre punti nell'angolo in alto a destra del browser.",
  selectSiteSettings: "Seleziona le impostazioni del sito",
  foundAdvanced: "Questo può essere trovato sotto il sottotitolo avanzato.",
  selectCamera: "Seleziona fotocamera",
  selectWebsite: "Seleziona 'https://scan.laava.id'",
  changePermission: "Modifica l'autorizzazione per accedere alla tua fotocamera, per consentire.",
  returnToWebscanner: "Torna allo scanner web per eseguire la scansione.",
  lookForLaava: "Cerca Laava.",
  simplyScan: "Scansiona semplicemente.",
  shopWithAssurance: "Acquista con sicurezza.",
  laavaEnablesBrands:
    "Laava consente ai marchi di fornire garanzie sulla qualità dei loro prodotti utilizzando una nuova tecnologia sicura sviluppata con il CSIRO.",
  aboutLaava: "A proposito di Laava",
  knowYouReGetting: "Sappi che stai ottenendo la cosa reale e non un sostituto",
  laavaIsANewKind:
    "Laava è un nuovo tipo di codice a barre che può aiutarti a garantire che il prodotto per cui stai pagando sia autentico e proteggerti da imitazioni di qualità inferiore.",
  everyFingerprint: "Ogni impronta è completamente unica e diversa dalle altre",
  aFingerprintDoes:
    "Un'impronta digitale fa molto di più che indicare semplicemente quale prodotto o lotto stai tenendo. È un'impronta digitale individuale e completamente unica per il prodotto esatto che hai in mano.",
  seeBeyondTheLabel: "Guarda oltre l'etichetta e scopri di più sui tuoi prodotti",
  scanningAllowsYou:
    "La scansione consente di visualizzare più informazioni oltre a ciò che c'è sull'etichetta. Scopri informazioni specifiche su quell'articolo esatto, ad esempio dove è stato prodotto o quando è stato confezionato.",
  ourCompany: "La nostra azienda",
  laavaWasFounded:
    "Laava è stata fondata in Australia nel 2016. Il concetto e la soluzione tecnologica sono stati sviluppati da esperti mondiali nel campo dell'ottica, blockchain, marketing, informazioni sui consumatori.",
  needHelpScanning: "Hai bisogno di aiuto per la scansione?",
  youCanFindDetails:
    "Puoi trovare i dettagli sulla scansione, insieme alle risposte ai problemi comuni, nella nostra guida.",
  getHelp: "Ottieni aiuto con la scansione",
  howScan: "Come scansionare",
  problemsWithTheFingerprint: "Problemi con l'impronta digitale",
  commonProblems: "Problemi comuni",
  getResults:
    "Per ottenere i migliori risultati, questi suggerimenti possono aiutarti a superare alcuni problemi comuni che possono influire sulla qualità della scansione.",
  readyToScan: "Pronto per scansionare un'impronta digitale?",
  needMoreHelp: "Serve ancora aiuto?",
  scanFingerprint: "Scansiona la tua impronta digitale",
  bringFingerprint:
    "Mettere a fuoco l'impronta digitale nella vista della telecamera e tenerla in posizione finché non viene rilevata l'impronta digitale. L'operazione potrebbe richiedere alcuni istanti a seconda della velocità di connessione.",
  locateSmartFingerprint: "Individua la tua Smart Fingerprint™",
  fingerprintsCanBeFound: "Le impronte digitali possono essere trovate su una varietà di prodotti di uso quotidiano.",
  alignTheFingerprint: "Allineare l'impronta digitale utilizzando la guida",
  alignTheGuide: "Allineare la guida agli anelli interno ed esterno dell'impronta digitale.",
  snapPhoto: "Scatta una foto",
  getYourFingerprint: "Metti a fuoco la tua impronta digitale e tieni la mano ferma.",
  light: "Leggero",
  likeAnyCamera:
    "Come qualsiasi fotocamera, i migliori risultati sono in buona luce. Assicurati che la tua impronta digitale sia ben illuminata e osserva i riflessi.",
  keepYourHands: "Tieni le mani ferme",
  tryKeepYourHand:
    "Cerca di tenere ferme la mano e la fotocamera quando scatti la foto, in modo che l'immagine sia nitida.",
  considerTheAngle: "Considera l'angolo",
  scanYourFingerprint:
    "Scansiona la tua impronta digitale in una posizione che è davanti alla fotocamera e riempie la maggior parte della vista (rimanendo a fuoco).",
  watchOutForShadow: "Attenti alle ombre",
  whenScanning: "Durante la scansione, assicurati che il telefono non proietti un'ombra scura sull'impronta digitale.",
  haveAnyQuestion: "Hai domande o feedback? Ci piacerebbe sentirti.",
  email: "E-mail",
  startingCamera: "Fotocamera iniziale",
  searching: "Scansione",
  analysing: "Analizzando",
  sending: "Invio",
  sendingForVerification: "Invio per verifica",
  mightTakeAFewMoments: "Potrebbero volerci alcuni istanti",
  placeCodeWithinFrame: "Posizionare il codice all'interno della cornice da scansionare",
  needHelp: "Ho bisogno di aiuto?",
  googlePrivacyPolicy: "Informativa sulla privacy di Google",
  laavaPrivacy: "Laava Privacy",
  laavaHasAPrivacy:
    "Laava ha un {{ manifesto }} che spiega le nostre politiche che riguardano te e la tua privacy come utente di Laava Fingerprints. Separatamente, abbiamo anche una {{ policy }} che si applica solo nelle circostanze limitate in cui dobbiamo raccogliere informazioni personali.",
  privacyManifesto: "Manifesto sulla privacy",
  corporatePrivacy: "Informativa sulla privacy aziendale",
  laavaWebscannerTerm: "Termini e condizioni di Laava – Webscanner",
  general: "Generale",
  websiteUse: "Utilizzo del sito web",
  intellectualProperty: "Proprietà intellettuale",
  limitionOfLiability: "Limitazione di responsabilità",
  indemnity: "Indennizzo",
  otherWebsites: "Altri siti web e terze parti",
  entireAgreement: "Intero accordo",
  invalidity: "Invalidità ed esecutività",
  privacy: "Privacy",
  applicableLaw: "Legge applicabile",
  howToContact: "Come contattarci",
  termsP00:
    "Grazie per aver effettuato l'accesso al sito Web di Laava (il sito Web). Il sito Web è di proprietà e gestito da Laava ID Pty Ltd (ABN 99 617 775 578) (Laava).",
  termsP01:
    "L'utilizzo del sito Web è regolato dalle presenti Condizioni d'uso. Accedendo e utilizzando il sito Web, accetti di essere vincolato da queste Condizioni d'uso.",
  termsP02:
    "Laava può modificare parte o tutte le presenti Condizioni d'uso in qualsiasi momento. Le Condizioni d'Uso più aggiornate saranno sempre pubblicate sul Sito. L'uso successivo o continuato del sito Web costituirà l'accettazione di qualsiasi modifica apportata alle presenti Condizioni d'uso. Se ti opponi a queste Condizioni d'uso, oa qualsiasi modifica ad esse apportata, dovresti cessare l'uso del sito web.",
  termsP03: "Questi termini sono stati aggiornati l'ultima volta il 26 novembre 2018.",
  termsP1: "È possibile utilizzare il sito Web o qualsiasi contenuto solo per scopi non commerciali.",
  termsP2: "Le informazioni contenute nel sito Web sono solo a scopo informativo generale.",
  termsP3:
    "Il Sito Web e le informazioni, i collegamenti, i documenti o la relativa grafica contenuti nel Sito Web (insieme, il Contenuto) sono forniti 'così come sono' . Qualsiasi affidamento sul Contenuto è rigorosamente a proprio rischio. È necessario effettuare le proprie richieste per determinare se il Contenuto è appropriato per l'uso previsto.",
  termsP4:
    "Sebbene Laava compia ogni ragionevole sforzo per garantire che il sito Web sia sempre disponibile e pienamente operativo , l'accesso al sito Web potrebbe essere sospeso senza preavviso in caso di guasto del sistema, manutenzione o qualsiasi motivo al di fuori del controllo di Laava.",
  termsP5: "Laava può modificare o interrompere, temporaneamente o permanentemente, il:",
  termsP6: "Sito Web o qualsiasi Contenuto, funzione o servizio sul Sito Web; o",
  termsP7: "il percorso o la posizione di un collegamento,",
  termsP8:
    "in qualsiasi momento senza preavviso. Laava declina ogni responsabilità in relazione a questi, o simili, cambiamenti, interruzioni o interruzioni del sito Web o di qualsiasi parte del sito Web.",
  termsP9:
    "Laava si riserva il diritto di interrompere l'accesso al sito Web e impedirne l'ulteriore accesso senza preavviso o preavviso in risposta a violazioni delle presenti Condizioni d'uso da parte dell'utente, violazioni di qualsiasi legge applicabile o attività sospette.",
  termsP10: "Quando utilizzi il Sito accetti di non:",
  termsP11: "fornire informazioni false, fuorvianti o ingannevoli",
  termsP12: "utilizzare il sito Web in modo tale da violare, o che potrebbe violare, qualsiasi legge o regolamento",
  termsP13:
    "utilizzare il sito Web in un modo che potrebbe causare a Laava una violazione di qualsiasi legge o regolamento",
  termsP14: "utilizzare il sito Web in un modo che si riflette negativamente su Laava o qualsiasi altra terza parte",
  termsP15:
    "raccogliere informazioni su altri utenti del sito Web per scopi diversi da quelli espressamente autorizzati da noi",
  termsP16:
    "intraprendere qualsiasi azione che, secondo Laava, imponga un onere irragionevole sull'infrastruttura del sito Web;",
  termsP17:
    "distribuire un virus informatico o qualsiasi programma o codice simile che potrebbe interrompere o disabilitare il sito Web",
  termsP18:
    "utilizzare un dispositivo, qualsiasi software o processo o altri mezzi automatizzati per recuperare, raschiare, copiare o indicizzare il sito Web o qualsiasi contenuto per qualsiasi scopo; o",
  termsP19:
    "recuperare, raschiare, copiare o indicizzare il sito Web o qualsiasi contenuto allo scopo di creare un database di informazioni immobiliari.",
  termsP20:
    "Il sito Web e tutta la proprietà intellettuale nel contenuto del sito Web, inclusi (a titolo esemplificativo) qualsiasi testo, grafica, fotografia, loghi o marchi sono di proprietà o concessi in licenza a Laava, se non diversamente indicato.",
  termsP21:
    "Salvo ove necessario per la visualizzazione dei Contenuti del Sito, ove consentito dalla legge, o laddove abbiate ottenuto l'espresso consenso scritto di Laava, non potete riprodurre, ricompilare, decompilare, disassemblare, decodificare, creare opere derivate da, eseguire, modificare, adattare , pubblicare, archiviare, utilizzare, trasmettere elettronicamente o in altro modo, distribuire o sfruttare in altro modo, con qualsiasi mezzo, in tutto o in parte, il sito Web o il contenuto del sito Web per scopi diversi dal proprio uso personale e non commerciale .",
  termsP22: "L'utilizzo del sito Web avviene a proprio rischio.",
  termsP23:
    "Nella misura massima consentita dalla legge, Laava declina ogni responsabilità , esplicita o implicita, inclusi, ma non limitati a:",
  termsP24: "la completezza, accuratezza, affidabilità, idoneità o disponibilità rispetto al Sito o ai Contenuti",
  termsP25: "che il Sito e il Contenuto saranno privi di errori, o che i difetti saranno corretti",
  termsP26: "che l'accesso al sito Web sarà ininterrotto, sicuro o privo di errori",
  termsP27: "che il sito Web è privo di virus o altri componenti dannosi; e",
  termsP28:
    "la qualità, l'accuratezza o l'idoneità allo scopo di qualsiasi sito Web collegato o materiale di terze parti .",
  termsP29:
    "Nella misura massima consentita dalla legge, Laava e i suoi amministratori, dipendenti e agenti, non sono responsabili nei tuoi confronti per qualsiasi perdita o danno di qualsiasi tipo (incluso il danno consequenziale) in qualsiasi modo causato, sia per contratto, illecito inclusa negligenza, statuto o altro derivante in relazione al Sito o ai Contenuti.",
  termsP30:
    "Nulla in questi Termini di utilizzo intende escludere, limitare o modificare i diritti che potresti avere in base a qualsiasi legge (inclusa in Australia, la legge australiana sui consumatori) che non possono essere esclusi, limitati o modificati per accordo. Se le presenti Condizioni d'uso sono regolate dalla legge australiana sui consumatori, la nostra responsabilità nei tuoi confronti per il mancato rispetto di una garanzia del consumatore in relazione a qualsiasi servizio è limitata alla rifornitura dei servizi o al pagamento di tale rifornitura.",
  termsP31:
    "L'utente indennizza Laava in relazione a qualsiasi responsabilità sostenuta da Laava per eventuali perdite, responsabilità o danni, spese, comprese, senza limitazioni, ragionevoli spese legali e contabili, in qualunque modo causate, che è subita da Laava a seguito della violazione delle presenti Condizioni d'uso o il tuo utilizzo del sito web.",
  termsP32:
    "Il sito Web può contenere collegamenti a siti Web di terzi, annunci pubblicitari o informazioni su tali siti Web.",
  termsP33:
    "Laava non ha alcun controllo sulla natura, il contenuto e la disponibilità dei siti Web di terzi . L'inclusione di collegamenti ad altri siti Web non implica necessariamente una sponsorizzazione, approvazione o approvazione di tali siti Web o delle opinioni espresse al loro interno.",
  termsP34:
    "Laava non si assume alcuna responsabilità per il contenuto di tali siti web. Qualsiasi utilizzo di siti Web e informazioni di terzi è a proprio rischio.",
  termsP35:
    "Le presenti Condizioni d'uso rappresentano l'intero accordo tra l'utente e Laava in merito all'utilizzo e all'accesso al sito Web e all'utilizzo e all'accesso al contenuto in esso contenuto. Nessun altro termine deve essere incluso nelle presenti Condizioni d'uso, salvo laddove sia richiesto da qualsiasi legislazione del Commonwealth of Australia o di qualsiasi Stato o Territorio dell'Australia. Sono espressamente esclusi tutti i termini impliciti ad eccezione di quelli impliciti per statuto e che non possono essere espressamente esclusi.",
  termsP36:
    "Se una qualsiasi disposizione di questi Termini di utilizzo non è valida ai sensi della legge di qualsiasi giurisdizione, la disposizione è applicabile in tale giurisdizione nella misura in cui non è valida, indipendentemente dal fatto che sia in termini separabili o meno.",
  termsP37:
    "Tutte le informazioni personali raccolte da Laava da te verranno gestite in conformità con il Privacy Act 1988 ( Cth ) e la {{policy}}",
  termsP38:
    "Per ulteriori informazioni su come Laava gestisce le tue informazioni personali durante la scansione delle impronte digitali Laava, fai riferimento a {{manifesto}}",
  termsP39:
    "Laava si riserva il diritto in qualsiasi momento e di volta in volta di modificare, interrompere temporaneamente o permanentemente lo Scanner del sito Web, o parte di esso, per qualsiasi motivo. Laava declina ogni responsabilità in conseguenza di qualsiasi interruzione o interruzione dello Scanner del sito Web, o di qualsiasi parte di esso",
  termsP40: "Puoi contattare Laava tramite:",
  contact: "Contatto",
  privacyOfficer: "Responsabile della privacy",
  address: "Indirizzo",
  phone: "Telefono",
  laavaPrivacyManifesto: "Manifesto sulla privacy di Laava",
  privacyManifestoIntroduction: "Laava – Manifesto sulla privacy",
  ourApproach: "Il nostro approccio alla tua privacy",
  whatInformationScan: "Quali informazioni vengono raccolte quando eseguo la scansione di un'impronta digitale Laava?",
  whatInformationDetect: "Quali informazioni vengono raccolte quando rileviamo attività sospette?",
  howDoesLaava: "In che modo Laava utilizza i cookie?",
  sharingInformation: "Condividere informazioni",
  privacyManifestoP1:
    "Questo documento è il nostro Manifesto sulla privacy che delinea la nostra politica in merito alla raccolta di informazioni quando si esegue la scansione di un'impronta digitale Laava Smart utilizzando un browser o uno scanner WeChat Laava.",
  privacyManifestoP2:
    "Abbiamo un {{ link }} separato che stabilisce come gestiamo le tue informazioni personali in altre circostanze aziendali, ad esempio se fai domanda per una posizione con noi, se sei un potenziale investitore, rappresentante di un partner, cliente, fornitore o nostro fornitore di servizi, o se contatti il nostro help desk o invii una domanda o una domanda con noi.",
  privacyManifestoP3:
    "Non desideriamo raccogliere nessuna delle tue informazioni personali durante la scansione di Laava Smart Fingerprints. Il rispetto della tua privacy è la nostra principale preoccupazione, motivo per cui non richiediamo nomi, accessi , numeri di telefono, e-mail o qualsiasi altra informazione che ti identifichi personalmente.",
  privacyManifestoP4:
    "La scansione di Laava Smart Fingerprints è al centro di tutto ciò che facciamo. Vogliamo fornire un ambiente sicuro, privato e senza preoccupazioni per tutti coloro che scansionano un'impronta digitale Laava Smart. La scansione di Laava Smart Fingerprints significa niente problemi e niente occhi indiscreti.",
  privacyManifestoP5:
    "Pensiamo che così tante aziende possano fare di meglio per quanto riguarda la privacy dei consumatori e vogliamo aprire la strada. Solo perché puoi raccogliere dati, non significa che dovresti.",
  privacyManifestoP6:
    "Quando si esegue la scansione di un'impronta digitale Laava Smart, acquisiamo un'immagine della scansione e altre informazioni di scansione, o metadati, necessari per autenticare l'impronta digitale Laava Smart e aggiornarne il record di stato. Tali metadati possono includere l'URL da cui ha avuto origine la scansione, la posizione della scansione (solitamente derivata da un indirizzo IP o da una posizione GPS) e l'ora di ciascuna scansione. Assegniamo e registriamo anche un ID scanner anonimo ad ogni scansione e potremmo utilizzare informazioni limitate sul dispositivo come le impostazioni del browser e della fotocamera.",
  privacyManifestoP7:
    "È importante sottolineare che tutte le informazioni che raccogliamo quando esegui la scansione di un'impronta digitale Laava Smart sono rese anonime e non possono essere facilmente utilizzate per identificarti ( Informazioni anonime) . Se l'immagine della tua scansione include informazioni che potrebbero essere utilizzate per identificarti, non cercheremo di farlo.",
  privacyManifestoP8:
    "Se esegui la scansione di un'impronta digitale Laava Smart nel nostro mini-programma WeChat , sarai anche soggetto all'informativa sulla privacy di WeChat.",
  privacyManifestoP9:
    "Per garantire che Laava sia uno strumento efficace contro la contraffazione e le frodi e per mantenere il vostro ambiente di scansione sicuro e mantenere un'elevata qualità del servizio, delle prestazioni e della sicurezza, utilizziamo una serie di metodi per determinare se l'attività di scansione di una singola impronta digitale Laava Smart è sospettoso. Quando rileviamo attività sospette, possiamo condurre un'analisi approfondita dell'attività di scansione e dei metadati, nonché trasmettere queste informazioni ai nostri clienti e partner (insieme Clienti) .",
  privacyManifestoP10:
    "Laava utilizza i cookie del browser sul proprio sito web. Un cookie del browser è un piccolo frammento di dati che viene memorizzato sul tuo dispositivo per aiutare i siti web a ricordare cose sulla tua navigazione. I cookie ci aiutano a vedere quali parti del nostro sito Web sono più popolari, il che ci consente di migliorare la tua esperienza.",
  privacyManifestoP11:
    "Per aiutare a migliorare il nostro sito Web, alcune informazioni possono essere raccolte come informazioni statistiche e possono includere indirizzo IP, tipo di browser, lingua e orari di accesso. Gli strumenti utilizzati sul nostro sito Web registrano le informazioni in un modo che non ti identifica come individuo. Il tuo browser o dispositivo potrebbe consentirti di bloccare, limitare l'uso o rimuovere i cookie.",
  privacyManifestoP12:
    "Quando si esegue la scansione di Laava Smart Fingerprints utilizzando uno scanner basato su browser, è possibile che vengano utilizzati dei cookie, ma la cronologia delle scansioni non verrà memorizzata.",
  privacyManifestoP13:
    "Il nostro sito Web può anche utilizzare di volta in volta strumenti di siti Web di terze parti, ad esempio Google Analytics e strumenti di sondaggi online. Per ulteriori informazioni su come Google raccoglie ed elabora i dati, consulta l'informativa sulla privacy di Google e le relative informazioni all'indirizzo {{ link }}",
  privacyManifestoP14:
    "I clienti sono ovviamente interessati ad analizzare i record di scansione di Laava Smart Fingerprints associati ai loro articoli. Condividiamo le informazioni di scansione su Laava Smart Fingerprints con i clienti, di solito in forma aggregata. Quando condividiamo informazioni con i clienti, diciamo loro solo ciò che devono sapere, ad esempio dove, quando e quante volte è stata scansionata la loro impronta digitale Laava Smart. Dal momento che non raccogliamo le tue informazioni personali, non possiamo condividerle con loro.",
  privacyManifestoP15:
    "Consentiamo inoltre ai Clienti di interagire con te su base personale quando esegui la scansione di un'impronta digitale Laava Smart. Nell'ambito di questo impegno, i Clienti possono richiederti informazioni personali. Pensiamo che sia fantastico, ma in Laava la condivisione di qualsiasi informazione personale avviene sempre in modo trasparente e con adesione. Sei sempre responsabile e in grado di scegliere ciò che condividi.",
  privacyManifestoP16:
    "Laava non vedrà né raccoglierà le tue informazioni personali, invece tutte le informazioni personali che condividi saranno raccolte e gestite dai nostri clienti in conformità con le loro politiche sulla privacy, che ti verrà chiesto di accettare.",
  privacyManifestoP17:
    "Laava può condividere qualsiasi informazione fornita dall'utente quando ritiene che il rilascio sia appropriato per il rispetto della legge, per facilitare i procedimenti giudiziari o per proteggere i diritti, la proprietà o la sicurezza di Laava, degli utenti di questo sito Web e di altri.",
  laavaPrivacyPolicy: "Informativa sulla privacy di Laava",
  privacyPolicyT1: "Informazioni su questa politica sulla privacy aziendale",
  privacyPolicyT2: "Quando raccogliamo le tue informazioni personali?",
  privacyPolicyT3: "Quali informazioni personali raccogliamo e gestiamo?",
  privacyPolicyT4: "Perché raccogliamo le tue informazioni personali?",
  privacyPolicyT5: "A chi potremmo divulgare le tue informazioni personali?",
  privacyPolicyT6: "Cosa succede se non possiamo raccogliere le tue informazioni personali?",
  privacyPolicyT7: "Visitatori del nostro sito Web e cookie",
  privacyPolicyT8: "Come proteggiamo le tue informazioni personali?",
  privacyPolicyT9: "Comunicazioni di marketing diretto",
  privacyPolicyT10: "Come puoi accedere e correggere le tue informazioni personali?",
  privacyPolicyT11: "Come si può presentare un reclamo sulla privacy?",
  privacyPolicyT12: "Come contattarci",
  privacyPolicyP1:
    "Pensiamo che così tante aziende possano fare di meglio per quanto riguarda la privacy dei consumatori e vogliamo aprire la strada. Solo perché puoi raccogliere dati, non significa che dovresti. Apprezziamo e rispettiamo la tua privacy e prendiamo sul serio la protezione delle tue informazioni personali.",
  privacyPolicyP2:
    "Questa Informativa sulla privacy aziendale NON riguarda ciò che accade a te o alle tue informazioni personali quando esegui la scansione di un'impronta digitale Laava. La nostra politica di non raccogliere informazioni personali durante la scansione delle impronte digitali Laava è trattata nel nostro {{ link }} separato",
  privacyPolicyP3:
    "Questa Informativa sulla privacy aziendale stabilisce come Laava ID Pty Ltd (ABN 99617 775 578) (Laava, we, our, us) gestisce le tue informazioni personali nelle circostanze limitate in cui dobbiamo raccogliere informazioni personali, ad esempio se richiedi un lavorare con noi, cercare di investire o lavorare con Laava (come rappresentante di un partner, cliente, fornitore o fornitore di servizi), o se contatti il nostro help desk o invii una domanda.",
  privacyPolicyP4:
    "Descrive le informazioni personali che raccogliamo su di te, quando e come le utilizziamo. Descrive inoltre i tuoi diritti alla privacy come individuo e come contattarci in caso di problemi di privacy. Questa Informativa sulla privacy può cambiare di volta in volta e quindi potresti volerla rivedere regolarmente.",
  privacyPolicyP5:
    "Come stabilito nel nostro Manifesto sulla privacy, non raccogliamo alcuna informazione personale in relazione alla scansione di un'impronta digitale Laava.",
  privacyPolicyP6:
    "Inoltre, non vediamo né raccogliamo le tue informazioni personali quando scegli di condividere le informazioni personali con i proprietari di marchi che utilizzano le nostre impronte digitali Laava (marchi). Questo è anche trattato nel nostro Manifesto sulla privacy.",
  privacyPolicyP7:
    "Tuttavia, ci sono alcune circostanze limitate in cui abbiamo bisogno di raccogliere le tue informazioni personali direttamente da te, incluso quando tu:",
  privacyPolicyP8:
    "comunicare con noi per telefono, e-mail, online (anche tramite il nostro sito Web o l'help desk) o di persona",
  privacyPolicyP9: "quando fai domanda per una posizione con noi",
  privacyPolicyP10: "quando fai domanda per diventare un investitore; o",
  privacyPolicyP11:
    "quando interagisci con noi in qualità di cliente, partner, fornitore o fornitore di servizi potenziale o permanente.",
  privacyPolicyP12:
    "Le informazioni personali sono qualsiasi informazione o opinione (vera o meno, registrata in forma materiale o meno) su di te come individuo identificato o un individuo ragionevolmente identificabile. I tipi di informazioni personali che raccogliamo e gestiamo possono includere:",
  privacyPolicyP13: "informazioni di contatto, come nome, indirizzo, numero di telefono, e-mail e/o indirizzo IP",
  privacyPolicyP14:
    "informazioni sul tuo ruolo all'interno della tua organizzazione, se sei un nostro partner, cliente, fornitore o fornitore di servizi esistente o potenziale",
  privacyPolicyP15:
    "dettagli finanziari e altri dettagli rilevanti che condividi con noi se fai domanda per diventare un investitore",
  privacyPolicyP16:
    "se fai domanda per una posizione con noi, potremmo raccogliere informazioni sulle tue qualifiche, esperienza di abilità, carattere, controlli di screening e storia lavorativa; e",
  privacyPolicyP17:
    "altre informazioni che ci fornisci tramite il nostro help desk (o in qualsiasi domanda che ci invii).",
  privacyPolicyP18:
    "Raccogliamo, conserviamo, utilizziamo e divulghiamo informazioni personali principalmente per rispondere a domande, per prendere in considerazione la tua candidatura per una posizione presso di noi o per considerarti un nostro investitore, cliente, fornitore o partner. Potremmo anche raccogliere le tue informazioni personali per motivi correlati, tra cui:",
  privacyPolicyP19: "finalità amministrative, compresa la tenuta dei registri e la rendicontazione interna",
  privacyPolicyP20: "per comunicare con te",
  privacyPolicyP21: "per finalità di marketing; e",
  privacyPolicyP22: "per rispettare le leggi e i regolamenti applicabili.",
  privacyPolicyP23:
    "Nelle limitate circostanze elencate nella sezione 2 sopra in cui abbiamo raccolto le tue informazioni personali, potremmo divulgare le tue informazioni personali a terzi. I tipi di terze parti con cui scambiamo informazioni personali includono:",
  privacyPolicyP24:
    "i nostri fornitori di servizi, inclusi contabilità, revisione contabile, legale, bancaria, servizi di pagamento, consegna, hosting di dati, ricerca, reclutamento, assicurazione, pensione integrativa, buste paga, formazione e servizi tecnologici",
  privacyPolicyP25:
    "per le persone che si candidano a lavorare con noi, arbitri e fornitori di controlli di screening ( ad es . per il controllo dei precedenti, dell'idoneità al lavoro, dell'idoneità e della fedina penale); e",
  privacyPolicyP26: "alle forze dell'ordine e ad altre organizzazioni ove richiesto o consentito dalla legge.",
  privacyPolicyP27:
    "Non divulgheremo le tue informazioni personali a nessun destinatario all'estero. Tuttavia, potremmo archiviare le tue informazioni personali con fornitori di hosting di dati sicuri come Google, che potrebbero archiviare tali informazioni nelle seguenti posizioni: ({{link}}). Ove possibile, richiederemo che le tue informazioni personali rimangano archiviate in Australia. Se abbiamo bisogno di divulgare le tue informazioni personali a qualsiasi destinatario all'estero, aggiorneremo la presente Informativa sulla privacy specificando i paesi in cui si trovano.",
  privacyPolicyP28: "Se non ci fornisci le informazioni personali quando richiesto, potremmo non essere in grado di:",
  privacyPolicyP29: "considera la tua candidatura per unirti a noi come dipendente",
  privacyPolicyP30:
    "considera la tua candidatura per diventare investitore, cliente, partner; fornitore o prestatore di servizi; e",
  privacyPolicyP31: "rispondere alle tue domande o richieste di informazioni.",
  privacyPolicyP32:
    "Un cookie del browser è un piccolo frammento di dati che viene memorizzato sul tuo dispositivo per aiutare i siti web e le applicazioni mobili a ricordare cose su di te. Sebbene non utilizziamo i cookie nell'app mobile Laava, utilizziamo i cookie sul nostro sito Web. I cookie ci aiutano a vedere quali parti del nostro sito Web sono più popolari, il che ci consente di concentrarci sul miglioramento di quegli aspetti del sito Web che miglioreranno la tua esperienza e di fornirti un'esperienza migliore, più intuitiva e soddisfacente. Il tuo browser o dispositivo potrebbe consentirti di bloccare o limitare in altro modo l'uso dei cookie. Potresti anche essere in grado di rimuovere i cookie dal tuo browser.",
  privacyPolicyP33:
    "Per aiutare a migliorare il servizio che forniamo attraverso il nostro sito Web, alcune informazioni potrebbero essere raccolte dal tuo browser. Queste informazioni vengono raccolte come informazioni statistiche e includono l'indirizzo IP, il tipo di browser, la lingua e gli orari di accesso. In molti casi, gli strumenti utilizzati sul nostro sito Web registrano le informazioni in un modo che non ti identifica come individuo. In altri casi, le informazioni possono essere informazioni personali, nel qual caso si applicherà la presente Informativa sulla privacy.",
  privacyPolicyP34:
    "Il nostro sito Web può anche utilizzare di volta in volta strumenti di siti Web di terze parti, ad esempio Google Analytics e strumenti di sondaggi online. Per ulteriori informazioni su come Google raccoglie ed elabora i dati, consulta l'informativa sulla privacy di Google e le relative informazioni all'indirizzo: ( {{ link }}).",
  privacyPolicyP35:
    "Adottiamo tutte le misure ragionevoli per proteggere le informazioni personali da perdita, interferenza, uso improprio o accesso, modifica o divulgazione non autorizzati. Questi passaggi possono includere controlli di accesso, crittografia e locali protetti. Archiviamo le informazioni personali sia in forma fisica che elettronica, a volte con l'assistenza di terze parti come i fornitori di hosting di dati.",
  privacyPolicyP36:
    "Di tanto in tanto, se hai acconsentito a ricevere informazioni di marketing da noi, potremmo inviarti comunicazioni di marketing diretto relative ai nostri servizi.",
  privacyPolicyP37:
    "Potremmo contattarti tramite messaggi elettronici ( ad es . e-mail), online (ad es. tramite il nostro sito Web), per posta e con altri mezzi, a meno che tu non rinunci successivamente o siamo soggetti a restrizioni legali. Puoi rinunciare alle comunicazioni di marketing contattandoci utilizzando i dettagli di contatto di seguito.",
  privacyPolicyP38:
    "L'accuratezza delle informazioni personali che conserviamo e utilizziamo è importante per noi. Adottiamo misure ragionevoli per garantire che le informazioni personali che gestiamo siano accurate, complete e aggiornate . Per aiutarci a mantenere le tue informazioni personali accurate, ti preghiamo di farci sapere se ci sono errori o modifiche nelle tue informazioni personali.",
  privacyPolicyP39:
    "Puoi richiedere l'accesso e la correzione delle informazioni personali che deteniamo su di te in qualsiasi momento contattando il nostro responsabile della privacy tramite i dettagli di contatto in fondo alla presente Informativa sulla privacy. Nella maggior parte dei casi, possiamo aiutarti in modo tempestivo e informale con queste richieste. In altri casi, potremmo aver bisogno di verificare la tua identità e chiederti di presentare la tua richiesta per iscritto.",
  privacyPolicyP40:
    "Puoi contattare il nostro responsabile della privacy utilizzando i dettagli di contatto in fondo a questa Informativa sulla privacy in caso di dubbi su come abbiamo gestito le tue informazioni personali. Ti risponderemo per farti sapere chi si occuperà della tua questione e quando puoi aspettarti un'ulteriore risposta. Potremmo richiederti ulteriori dettagli in merito alla tua preoccupazione e potremmo aver bisogno di impegnarci o consultarci con altre parti per indagare e affrontare il tuo problema. Conserveremo le registrazioni della tua richiesta e di qualsiasi risoluzione. Se non sei soddisfatto del modo in cui abbiamo trattato il tuo reclamo, puoi contattare l'Office of the Australian Information Commissioner.",
  privacyPolicyP41:
    "In caso di domande, reclami o reclami in relazione allo scanner del sito Web o ai presenti Termini e condizioni, è possibile contattare Laava all'indirizzo:",
  problemsTitle: "Problemi con l'impronta digitale",
  problemsIntro:
    "Se Laava Smart Fingerprint™ appare danneggiato o se riscontri problemi con la scansione, puoi contattarci e faremo del nostro meglio per aiutarti a risolvere il problema.",
  tryScanningAgain: "Prova a scansionare di nuovo",
  emailUs: "Mandaci una email",
  troubleDetectingFingerprint: "Stiamo riscontrando problemi nel rilevare un'impronta digitale",
  makeSureFingerprint: "Assicurati che l'impronta digitale sia ben illuminata e a fuoco",
  ensureInternet: "Assicurati che la tua connessione Internet sia buona",
  tryAgain: "Riprova",
  useLegacyScanner: "Usa scanner legacy",
  logIn: "Accedi",
  account: "Account",
  emailAddress: "Indirizzo e-mail",
  password: "Password",
  submit: "Invia",
  thereWasAProblemLoggingIn: "Si è verificato un problema durante l'accesso. Riprova.",
  loggedInAs: "Accesso effettuato come",
  logOut: "Esci"
}

export default it
