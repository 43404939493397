const pl = {
  pageTitle: "Skaner lawy",
  pageDescription: "Zeskanuj swój Laava Smart Fingerprint™, aby upewnić się, że produkt jest oryginalny.",
  loading: "Ładowanie",
  startingScanner: "Uruchamianie skanera",
  privacyPolicy: "Korzystając z Laava, wyrażasz zgodę na nasze",
  cookiesPolicy: "Polityka plików cookie",
  privacyAndCookies: "Prywatność i pliki cookie",
  termsOfUse: "Warunki korzystania",
  accept: "Zaakceptować",
  copyrightContent:
    "LAAVA, INTELIGENTNE ODCISKI PALCÓW, ODCISKI PALCÓW, UWIERZYTELNIANIE RZECZY, czek Laava, logo Laava i LAAVA ???? są znakami towarowymi firmy Laava ID Pty Ltd.",
  titleWarningAllowCamera: "Zezwól na dostęp do aparatu.",
  bodyWarningAllowCamera:
    "Ten skaner internetowy wykorzystuje aparat do skanowania odcisków palców. Zezwól na dostęp do kamery, aby spróbować ponownie.",
  titleWarningSupportedBrowser: "Otwórz {{przeglądarkę}}, aby zeskanować swój odcisk palca.",
  bodyWarningSupportedBrowser: "Przeglądarka, której używasz, nie jest obecnie obsługiwana.",
  activateTheCameraOn: "Aktywuj aparat na {{device}}.",
  thisWebscannerUsesTheCamera: "Ten skaner internetowy wykorzystuje aparat do skanowania odcisków palców.",
  scanNow: "Skanuj teraz",
  refresh: "Odświeżać",
  refreshThePage: "Odśwież stronę",
  loadAgain: "Załaduj ponownie scan.laava.id.",
  selectAllowWhenAsked: "Wybierz zezwolenie, gdy zostaniesz poproszony o dostęp do aparatu",
  whenAskedSelectAllow: "Na pytanie scan.laava.id Czy chcesz uzyskać dostęp do kamery, wybierz opcję Zezwól.",
  positionTheCode: "Umieść kod w ramce do zeskanowania",
  moveCloserToQR: "Przybliż się do kodu QR",
  moveFurtherFromQR: "Odsuń się od kodu QR",
  centerQRCode: "Wycentruj kod QR",
  unblurQrCode: "Kod QR jest rozmazany",
  goodQrCode: "Prawidłowy kod QR",
  badQrCode: "Nie wykryto kodu QR",
  qrCodeError: "Błąd kodu QR",
  seeSupportedBrowsers: "Uruchom przeglądarkę",
  supportedBrowsersPageTitle: "Ten skaner wymaga przeglądarki z obsługą aparatu.",
  supportedBrowsersPageBody:
    "System operacyjny Twojego urządzenia określi, które przeglądarki internetowe obsługują aparat.",
  howUnblockCamera: "Jak odblokować kamerę",
  help: "Pomoc",
  supportedBrowsersPageHelpBody: "Jeśli masz pytania lub wątpliwości, skontaktuj się z nami.",
  contactUs: "Skontaktuj się z nami",
  goToBrowserSettings: "Przejdź do ustawień przeglądarki",
  findSettingBrowser: "Możesz znaleźć ustawienia, dotykając trzech kropek w prawym górnym rogu przeglądarki.",
  selectSiteSettings: "Wybierz ustawienia witryny",
  foundAdvanced: "Można to znaleźć pod podtytułem zaawansowane.",
  selectCamera: "Wybierz aparat",
  selectWebsite: "Wybierz „https://scan.laava.id”",
  changePermission: "Zmień uprawnienia dostępu do kamery, aby zezwolić.",
  returnToWebscanner: "Wróć do skanera internetowego, aby zeskanować.",
  lookForLaava: "Szukaj Laavy.",
  simplyScan: "Po prostu skanuj.",
  shopWithAssurance: "Kupuj z gwarancją.",
  laavaEnablesBrands:
    "Laava umożliwia markom zapewnienie jakości ich produktów przy użyciu nowej, bezpiecznej technologii opracowanej wraz z CSIRO.",
  aboutLaava: "O Laavie",
  knowYouReGetting: "Wiedz, że otrzymujesz prawdziwą rzecz, a nie substytut",
  laavaIsANewKind:
    "Laava to nowy rodzaj kodu kreskowego, który może pomóc upewnić się, że produkt, za który płacisz, jest autentyczny i chroni Cię przed imitacjami niższej jakości.",
  everyFingerprint: "Każdy odcisk palca jest całkowicie wyjątkowy i niepodobny do żadnego innego",
  aFingerprintDoes:
    "Odcisk palca to znacznie więcej niż tylko wskazanie, jaki produkt lub partię posiadasz. Jest to indywidualny, całkowicie unikalny odcisk palca dla konkretnego produktu w Twojej dłoni.",
  seeBeyondTheLabel: "Spójrz poza etykietę i dowiedz się więcej o swoich produktach",
  scanningAllowsYou:
    "Skanowanie pozwala zobaczyć więcej informacji niż tylko to, co jest na etykiecie. Znajdź szczegółowe informacje o tym konkretnym elemencie, takie jak miejsce jego wyprodukowania lub data zapakowania.",
  ourCompany: "Nasza firma",
  laavaWasFounded:
    "Laava została założona w Australii w 2016 roku. Koncepcję i rozwiązanie technologiczne opracowali czołowi światowi eksperci w dziedzinie optyki, blockchain, marketingu, informacji konsumenckich.",
  needHelpScanning: "Potrzebujesz pomocy w skanowaniu?",
  youCanFindDetails:
    "Szczegółowe informacje na temat skanowania wraz z odpowiedziami na typowe problemy można znaleźć w naszym przewodniku pomocniczym.",
  getHelp: "Uzyskaj pomoc dotyczącą skanowania",
  howScan: "Jak skanować",
  problemsWithTheFingerprint: "Problemy z odciskiem palca",
  commonProblems: "Częste problemy",
  getResults:
    "Aby uzyskać najlepsze wyniki, te wskazówki mogą pomóc w przezwyciężeniu niektórych typowych problemów, które mogą mieć wpływ na jakość skanu.",
  readyToScan: "Gotowy do zeskanowania odcisku palca?",
  needMoreHelp: "Potrzebujesz więcej pomocy?",
  scanFingerprint: "Zeskanuj swój odcisk palca",
  bringFingerprint:
    "Ustaw ostrość odcisku palca w widoku z kamery i przytrzymaj, aż odcisk palca zostanie wykryty. Może to zająć kilka chwil w zależności od szybkości połączenia.",
  locateSmartFingerprint: "Zlokalizuj swój Smart Fingerprint™",
  fingerprintsCanBeFound: "Odciski palców można znaleźć na różnych produktach codziennego użytku.",
  alignTheFingerprint: "Wyrównaj odcisk palca za pomocą prowadnicy",
  alignTheGuide: "Wyrównaj prowadnicę z wewnętrznymi i zewnętrznymi pierścieniami odcisku palca.",
  snapPhoto: "Zrób zdjęcie",
  getYourFingerprint: "Ustaw ostrość na swoim odcisku palca i trzymaj rękę stabilnie.",
  light: "Światło",
  likeAnyCamera:
    "Jak w przypadku każdego aparatu, najlepsze wyniki uzyskuje się przy dobrym świetle. Upewnij się, że odcisk palca jest dobrze oświetlony i uważaj na odblaski.",
  keepYourHands: "Trzymaj ręce stabilnie",
  tryKeepYourHand: "Staraj się trzymać rękę i aparat nieruchomo podczas robienia zdjęcia, aby obraz był wyraźny.",
  considerTheAngle: "Rozważ kąt",
  scanYourFingerprint:
    "Zeskanuj swój odcisk palca w pozycji, która znajduje się z przodu aparatu i wypełnia większość widoku (zachowując ostrość).",
  watchOutForShadow: "Uważaj na cienie",
  whenScanning: "Podczas skanowania upewnij się, że telefon nie rzuca ciemnego cienia na odcisk palca.",
  haveAnyQuestion: "Masz pytania lub uwagi? Chcielibyśmy usłyszeć od ciebie.",
  email: "E-mail",
  startingCamera: "Uruchamianie aparatu",
  searching: "Łów",
  analysing: "Analizowanie",
  sending: "Wysyłanie",
  sendingForVerification: "Wysyłanie do weryfikacji",
  mightTakeAFewMoments: "Może to zająć kilka chwil",
  placeCodeWithinFrame: "Umieść kod w ramce do zeskanowania",
  needHelp: "Potrzebuję pomocy?",
  googlePrivacyPolicy: "Polityka prywatności Google",
  laavaPrivacy: "Prywatność Laavy",
  laavaHasAPrivacy:
    "Laava ma {{ manifest }}, który wyjaśnia nasze zasady, które mają wpływ na Ciebie i Twoją prywatność jako użytkownika Laava Fingerprints. Oddzielnie mamy również {{politykę}}, która ma zastosowanie tylko w ograniczonych okolicznościach, w których musimy gromadzić dane osobowe.",
  privacyManifesto: "Manifest dotyczący prywatności",
  corporatePrivacy: "Polityka prywatności firmy",
  laavaWebscannerTerm: "Warunki korzystania ze skanera internetowego Laava",
  general: "Ogólny",
  websiteUse: "Korzystanie ze strony internetowej",
  intellectualProperty: "Własność intelektualna",
  limitionOfLiability: "Ograniczenie odpowiedzialności",
  indemnity: "Odszkodowanie",
  otherWebsites: "Inne strony internetowe i osoby trzecie",
  entireAgreement: "Całość porozumienia",
  invalidity: "Nieważność i wykonalność",
  privacy: "Prywatność",
  applicableLaw: "Obowiązujące prawo",
  howToContact: "Jak się z nami skontaktować",
  termsP00:
    "Dziękujemy za dostęp do Witryny Laava (Witryny). Witryna jest własnością i jest zarządzana przez Laava ID Pty Ltd (ABN 99 617 775 578) (Laava).",
  termsP01:
    "Korzystanie z Witryny podlega niniejszym Warunkom użytkowania. Uzyskując dostęp do Witryny i korzystając z niej, wyrażasz zgodę na przestrzeganie niniejszych Warunków użytkowania.",
  termsP02:
    "Laava może w dowolnym momencie zmienić część lub całość niniejszych Warunków użytkowania. Najbardziej aktualne Warunki użytkowania będą zawsze publikowane w Witrynie. Dalsze lub dalsze korzystanie z Witryny będzie oznaczało akceptację wszelkich zmian wprowadzonych do niniejszych Warunków użytkowania. Jeśli sprzeciwiasz się niniejszym Warunkom użytkowania lub jakimkolwiek zmianom w nich wprowadzonym, powinieneś zaprzestać korzystania z Witryny.",
  termsP03: "Niniejsze warunki zostały ostatnio zaktualizowane 26 listopada 2018 r.",
  termsP1: "Możesz korzystać z Witryny lub jakiejkolwiek Treści wyłącznie w celach niekomercyjnych.",
  termsP2: "Informacje zawarte w Witrynie służą wyłącznie celom informacyjnym.",
  termsP3:
    "Witryna oraz informacje, łącza, dokumenty lub powiązane grafiki zawarte w Witrynie (łącznie Treść) są dostarczane „tak jak są”. Wszelkie poleganie na Treści odbywa się wyłącznie na własne ryzyko. Musisz przeprowadzić własne zapytania, aby ustalić, czy Treść jest odpowiednia do zamierzonego użytku.",
  termsP4:
    "Chociaż Laava dokłada wszelkich uzasadnionych starań, aby Witryna była dostępna i w pełni funkcjonalna przez cały czas, Twój dostęp do Witryny może zostać zawieszony bez powiadomienia w przypadku awarii systemu, prac konserwacyjnych lub z jakiegokolwiek powodu poza kontrolą Laava.",
  termsP5: "Laava może zmienić lub przerwać, tymczasowo lub na stałe,:",
  termsP6: "Witryny lub jakiejkolwiek Treści, funkcji lub usługi w Witrynie; Lub",
  termsP7: "ścieżka lub lokalizacja łącza,",
  termsP8:
    "dowolnym momencie bez powiadomienia. Laava zrzeka się wszelkiej odpowiedzialności w związku z takimi lub podobnymi zmianami, zaprzestaniem lub przerwami w działaniu Witryny lub jakiejkolwiek części Witryny.",
  termsP9:
    "Laava zastrzega sobie prawo do zablokowania dostępu użytkownika do Witryny i uniemożliwienia dalszego dostępu bez ostrzeżenia lub powiadomienia w odpowiedzi na naruszenie przez użytkownika niniejszych Warunków użytkowania, naruszenie obowiązujących przepisów lub podejrzane działania.",
  termsP10: "Korzystając z Witryny zgadzasz się nie:",
  termsP11: "podawać jakichkolwiek fałszywych, wprowadzających w błąd lub wprowadzających w błąd informacji",
  termsP12: "korzystać z Witryny w sposób, który narusza lub może naruszać jakiekolwiek przepisy prawa lub regulacje",
  termsP13:
    "korzystać z Witryny w sposób, który może spowodować naruszenie przez Laava jakiegokolwiek prawa lub regulacji",
  termsP14:
    "korzystać z Witryny w sposób, który świadczy niekorzystnie o Laava lub jakiejkolwiek innej osobie trzeciej",
  termsP15:
    "zbierać informacji o innych użytkownikach Witryny w jakimkolwiek celu innym niż wyraźnie przez nas dozwolony",
  termsP16:
    "podejmować jakichkolwiek działań, które zdaniem Laava nakładają nieuzasadnione obciążenie na infrastrukturę Witryny;",
  termsP17:
    "rozpowszechniać wirusa komputerowego lub podobnego programu lub kodu, który może zakłócić lub wyłączyć Witrynę",
  termsP18:
    "używać urządzenia, oprogramowania, procesu lub innych zautomatyzowanych środków do pobierania, kopiowania, kopiowania lub indeksowania Witryny lub jakiejkolwiek Treści w jakimkolwiek celu; Lub",
  termsP19:
    "pobierać, zeskrobywać, kopiować lub indeksować Witrynę lub jakąkolwiek Treść w celu stworzenia bazy danych zawierającej informacje o nieruchomościach.",
  termsP20:
    "Witryna i cała własność intelektualna w Treści w Witrynie, w tym (bez ograniczeń) wszelkie teksty, grafiki, zdjęcia, logo lub znaki towarowe są własnością Laava lub są licencjonowane przez Laava, o ile nie wskazano inaczej.",
  termsP21:
    "wyjątkiem przypadków, gdy jest to konieczne do przeglądania Treści w Witrynie, tam, gdzie jest to dozwolone przez prawo lub w przypadku uzyskania wyraźnej pisemnej zgody Laava, nie możesz reprodukować, rekompilować, dekompilować, dezasemblować, odtwarzać kodu źródłowego, tworzyć prac pochodnych, wykonywać, modyfikować, adaptować publikować, przechowywać, wykorzystywać, transmitować elektronicznie lub w inny sposób, rozpowszechniać ani w jakikolwiek inny sposób wykorzystywać w jakikolwiek sposób w całości lub w części Witryny lub Treści w Witrynie do celów innych niż własny, niekomercyjny użytek osobisty .",
  termsP22: "Korzystanie z Witryny odbywa się na własne ryzyko.",
  termsP23:
    "W maksymalnym zakresie dozwolonym przez prawo Laava zrzeka się wszelkich oświadczeń, gwarancji lub rękojmi, wyraźnych lub dorozumianych, w tym między innymi:",
  termsP24: "kompletność, dokładność, niezawodność, przydatność lub dostępność w odniesieniu do Witryny lub Treści",
  termsP25: "że Serwis i Treści będą wolne od błędów lub że wady zostaną usunięte",
  termsP26: "że Twój dostęp do Witryny będzie nieprzerwany, bezpieczny i wolny od błędów",
  termsP27: "że Witryna jest wolna od wirusów lub innych szkodliwych elementów; I",
  termsP28:
    "jakość, dokładność lub przydatność do celu jakichkolwiek powiązanych stron internetowych lub materiałów osób trzecich.",
  termsP29:
    "W maksymalnym zakresie dozwolonym przez prawo, Laava i jej dyrektorzy, pracownicy i agenci nie ponoszą odpowiedzialności wobec użytkownika za jakiekolwiek straty lub szkody jakiegokolwiek rodzaju (w tym straty wtórne) niezależnie od przyczyny, czy to w umowie, delikcie, w tym zaniedbaniu, ustawie lub w inny sposób w związku z Witryną lub Treścią.",
  termsP30:
    "Żadne z postanowień niniejszych Warunków użytkowania nie ma na celu wyłączenia, ograniczenia ani modyfikacji praw, które mogą przysługiwać użytkownikowi na mocy jakiegokolwiek prawa (w tym obowiązującego w Australii australijskiego prawa konsumenckiego), którego nie można wyłączyć, ograniczyć ani zmodyfikować w drodze umowy. Jeśli niniejsze Warunki użytkowania podlegają australijskiemu prawu konsumenckiemu, nasza odpowiedzialność wobec użytkownika za nieprzestrzeganie gwarancji konsumenckiej w odniesieniu do jakichkolwiek usług jest ograniczona do ponownego dostarczenia usług lub zapłaty za takie ponowne dostarczenie.",
  termsP31:
    "Zwalniasz Laava z odpowiedzialności z tytułu jakiejkolwiek odpowiedzialności poniesionej przez Laava za jakiekolwiek straty, zobowiązania lub szkody, wydatki, w tym między innymi uzasadnione opłaty prawne i księgowe, niezależnie od przyczyny, poniesione przez Laava w wyniku naruszenia przez Ciebie niniejszych Warunków użytkowania lub korzystania z Witryny.",
  termsP32:
    "Witryna może zawierać linki do stron internetowych osób trzecich, reklamy lub informacje na tych stronach.",
  termsP33:
    "Laava nie ma kontroli nad charakterem, treścią i dostępnością stron internetowych osób trzecich. Włączenie jakichkolwiek linków do innych stron internetowych niekoniecznie oznacza sponsorowanie, popieranie lub zatwierdzanie tych stron internetowych lub wyrażanych w nich poglądów.",
  termsP34:
    "Laava nie ponosi odpowiedzialności za treści na tych stronach. Jakiekolwiek korzystanie ze stron internetowych i informacji stron trzecich odbywa się na własne ryzyko.",
  termsP35:
    "Niniejsze Warunki użytkowania stanowią całość umowy między Tobą a Laava dotyczącej korzystania i dostępu do Witryny oraz korzystania i dostępu do Treści w niej zawartych. Żadne inne warunki nie mogą być zawarte w niniejszych Warunkach użytkowania, z wyjątkiem sytuacji, gdy jest to wymagane przez jakiekolwiek ustawodawstwo Związku Australijskiego lub dowolnego stanu lub terytorium Australii. Wszystkie dorozumiane warunki, z wyjątkiem tych dorozumianych przez ustawę i których nie można wyraźnie wykluczyć, są wyraźnie wyłączone.",
  termsP36:
    "Jeśli którekolwiek z postanowień niniejszych Warunków użytkowania jest nieważne na mocy prawa jakiejkolwiek jurysdykcji, wówczas postanowienie to jest wykonalne w tej jurysdykcji w zakresie, w jakim nie jest nieważne, niezależnie od tego, czy jest to postanowienie rozłączne, czy nie.",
  termsP37:
    "Wszystkie dane osobowe zbierane od Ciebie przez Laava będą przetwarzane zgodnie z ustawą o ochronie prywatności z 1988 r. (Cth) i {{policy}}",
  termsP38:
    "Aby uzyskać więcej informacji o tym, jak Laava obchodzi się z Twoimi danymi osobowymi podczas skanowania odcisków palców Laava, zapoznaj się z {{manifestem}}",
  termsP39:
    "Laava zastrzega sobie prawo w dowolnym czasie i od czasu do czasu do modyfikowania, tymczasowego lub stałego zaprzestania działania Skanera Witryny lub dowolnej jego części z dowolnego powodu. Laava zrzeka się wszelkiej odpowiedzialności w wyniku zaprzestania lub przerwy w działaniu Skanera Witryny lub jakiejkolwiek jego części",
  termsP40: "Możesz skontaktować się z Laava przez:",
  contact: "Kontakt",
  privacyOfficer: "Urzędnik ds. prywatności",
  address: "Adres",
  phone: "Telefon",
  laavaPrivacyManifesto: "Manifest prywatności Laava",
  privacyManifestoIntroduction: "Laava – manifest dotyczący prywatności",
  ourApproach: "Nasze podejście do Twojej prywatności",
  whatInformationScan: "Jakie informacje są zbierane podczas skanowania odcisku palca Laava?",
  whatInformationDetect: "Jakie informacje są zbierane, gdy wykryjemy podejrzaną aktywność?",
  howDoesLaava: "W jaki sposób Laava wykorzystuje pliki cookie?",
  sharingInformation: "Dzielenie się informacjami",
  privacyManifestoP1:
    "Ten dokument jest naszym Manifestem dotyczącym prywatności, który określa naszą politykę dotyczącą gromadzenia informacji podczas skanowania inteligentnego odcisku palca Laava za pomocą przeglądarki lub skanera WeChat Laava.",
  privacyManifestoP2:
    "Mamy oddzielny {{ link }}, który określa, w jaki sposób postępujemy z Twoimi danymi osobowymi w innych okolicznościach biznesowych, na przykład, jeśli ubiegasz się o stanowisko u nas, jeśli jesteś potencjalnym inwestorem, przedstawicielem partnera, klientem, dostawcą lub naszego usługodawcy lub jeśli skontaktujesz się z naszym działem pomocy lub prześlesz do nas pytanie lub zapytanie.",
  privacyManifestoP3:
    "Nie chcemy gromadzić żadnych danych osobowych podczas skanowania inteligentnych odcisków palców Laava. Poszanowanie Twojej prywatności jest dla nas najważniejsze, dlatego nie wymagamy imion, loginów, numerów telefonów, adresów e-mail ani żadnych innych informacji, które identyfikują Cię osobiście.",
  privacyManifestoP4:
    "Skanowanie inteligentnych odcisków palców Laava jest podstawą wszystkiego, co robimy. Chcemy zapewnić bezpieczne, prywatne i bezproblemowe środowisko dla każdego, kto skanuje inteligentny odcisk palca Laava. Skanowanie odcisków palców Laava Smart oznacza brak kłopotów i wścibskich oczu.",
  privacyManifestoP5:
    "Uważamy, że tak wiele firm może zrobić lepiej, jeśli chodzi o prywatność konsumentów, i chcemy przewodzić. To, że możesz zbierać dane, nie oznacza, że powinieneś.",
  privacyManifestoP6:
    "Kiedy skanujesz inteligentny odcisk palca Laava, przechwytujemy zdjęcie twojego skanu i inne informacje o skanowaniu lub metadane wymagane do uwierzytelnienia inteligentnego odcisku palca Laava i aktualizacji jego rejestru statusu. Te metadane mogą obejmować adres URL, z którego pochodzi skanowanie, lokalizację skanowania (zwykle pochodzącą z adresu IP lub lokalizacji GPS) oraz czas każdego skanowania. Przy każdym skanowaniu przypisujemy również i rejestrujemy anonimowy identyfikator skanera i możemy wykorzystywać ograniczone informacje o urządzeniu, takie jak ustawienia przeglądarki i aparatu.",
  privacyManifestoP7:
    "Co ważne, wszystkie informacje, które zbieramy podczas skanowania inteligentnego odcisku palca Laava, są anonimizowane i nie można ich łatwo wykorzystać do identyfikacji użytkownika (informacje anonimowe). Jeśli obraz Twojego skanu zawiera informacje, które mogłyby posłużyć do zidentyfikowania Ciebie, nie będziemy tego robić.",
  privacyManifestoP8:
    "Jeśli zeskanujesz inteligentny odcisk palca Laava w naszym miniprogramie WeChat, będziesz również podlegać polityce prywatności WeChat.",
  privacyManifestoP9:
    "Aby upewnić się, że Laava jest skutecznym narzędziem przeciwko fałszerstwom i oszustwom oraz aby zapewnić bezpieczeństwo środowiska skanowania i utrzymać wysoką jakość usług, wydajność i bezpieczeństwo, stosujemy szereg metod w celu ustalenia, czy aktywność skanowania indywidualnego odcisku palca Laava Smart Fingerprint jest podejrzany. Gdy wykryjemy podejrzaną aktywność, możemy przeprowadzić dokładną analizę aktywności skanowania i metadanych, a także przekazać te informacje naszym klientom i partnerom (razem Klientom).",
  privacyManifestoP10:
    "Laava używa plików cookie przeglądarki na swojej stronie internetowej. Plik cookie przeglądarki to mały fragment danych przechowywany na Twoim urządzeniu, aby pomóc stronom internetowym zapamiętywać informacje o przeglądaniu. Pliki cookie pomagają nam zobaczyć, które części naszej witryny są najpopularniejsze, co pozwala nam poprawić Twoje wrażenia.",
  privacyManifestoP11:
    "Aby pomóc w ulepszaniu naszej strony internetowej, niektóre informacje mogą być gromadzone jako informacje statystyczne i mogą obejmować adres IP, typ przeglądarki, język i czas dostępu. Narzędzia używane na naszej stronie zapisują informacje w sposób, który nie identyfikuje Cię jako osoby fizycznej. Twoja przeglądarka lub urządzenie może umożliwiać blokowanie, ograniczanie używania lub usuwanie plików cookie.",
  privacyManifestoP12:
    "Podczas skanowania odcisków palców Laava Smart Fingerprint za pomocą skanera opartego na przeglądarce mogą być używane pliki cookie, ale historia skanowania nie będzie przechowywana.",
  privacyManifestoP13:
    "Nasza witryna internetowa może również od czasu do czasu korzystać z narzędzi stron trzecich, na przykład Google Analytics i narzędzi do ankiet online. Aby uzyskać więcej informacji o tym, jak Google gromadzi i przetwarza dane, zapoznaj się z polityką prywatności Google i ich informacjami pod adresem {{ link }}",
  privacyManifestoP14:
    "Klienci są oczywiście zainteresowani analizą zapisów skanowania Laava Smart Fingerprints powiązanych z ich przedmiotami. Udostępniamy klientom informacje o skanowaniu inteligentnych odcisków palców Laava, zwykle w formie zbiorczej. Kiedy dzielimy się informacjami z Klientami, mówimy im tylko to, co muszą wiedzieć, na przykład gdzie, kiedy i ile razy ich odcisk palca Laava Smart Fingerprint został zeskanowany. Ponieważ nie zbieramy Twoich danych osobowych, nie możemy im ich udostępnić.",
  privacyManifestoP15:
    "Umożliwiamy również Klientom kontakt z Tobą osobiście, gdy skanujesz Inteligentny Odcisk Palca Laava. W ramach tego zaangażowania Klienci mogą żądać od Ciebie podania danych osobowych. Uważamy, że to świetnie, ale w Laava udostępnianie jakichkolwiek danych osobowych zawsze odbywa się na zasadzie przejrzystości i zgody. Zawsze masz kontrolę i możesz wybrać, co udostępniasz.",
  privacyManifestoP16:
    "Laava nie będzie widzieć ani gromadzić Twoich danych osobowych, zamiast tego wszelkie udostępniane przez Ciebie dane osobowe będą gromadzone i przetwarzane przez naszych klientów zgodnie z ich polityką prywatności, którą zostaniesz poproszony o zaakceptowanie.",
  privacyManifestoP17:
    "Laava może udostępnić wszelkie podane przez Ciebie informacje, jeśli uzna, że ich ujawnienie jest odpowiednie dla zachowania zgodności z prawem, ułatwienia postępowania sądowego lub ochrony praw, własności lub bezpieczeństwa Laava, użytkowników tej witryny i innych osób.",
  laavaPrivacyPolicy: "Polityka prywatności Laava",
  privacyPolicyT1: "O niniejszej Polityce Prywatności Firmy",
  privacyPolicyT2: "Kiedy zbieramy Twoje dane osobowe?",
  privacyPolicyT3: "Jakie dane osobowe zbieramy i przetwarzamy?",
  privacyPolicyT4: "Dlaczego zbieramy Twoje dane osobowe?",
  privacyPolicyT5: "Komu możemy ujawnić Twoje dane osobowe?",
  privacyPolicyT6: "Co zrobić, jeśli nie możemy zebrać Twoich danych osobowych?",
  privacyPolicyT7: "Odwiedzający naszą stronę internetową i pliki cookie",
  privacyPolicyT8: "Jak chronimy Twoje dane osobowe?",
  privacyPolicyT9: "Komunikacja marketingu bezpośredniego",
  privacyPolicyT10: "Jak możesz uzyskać dostęp do swoich danych osobowych i je poprawiać?",
  privacyPolicyT11: "Jak złożyć skargę dotyczącą naruszenia prywatności?",
  privacyPolicyT12: "Jak się z nami skontaktować",
  privacyPolicyP1:
    "Uważamy, że tak wiele firm może zrobić lepiej, jeśli chodzi o prywatność konsumentów, i chcemy przewodzić. To, że możesz zbierać dane, nie oznacza, że powinieneś. Cenimy i szanujemy Twoją prywatność i poważnie traktujemy ochronę Twoich danych osobowych.",
  privacyPolicyP2:
    "Niniejsza Polityka Prywatności firmy NIE dotyczy tego, co dzieje się z Tobą lub Twoimi danymi osobowymi po zeskanowaniu odcisku palca Laava. Nasza polityka niegromadzenia danych osobowych podczas skanowania odcisków palców Laava jest opisana w naszym oddzielnym {{ link }}",
  privacyPolicyP3:
    "Niniejsza korporacyjna polityka prywatności określa, w jaki sposób firma Laava ID Pty Ltd (ABN 99617 775 578) (Laava, my, nasz, nas) obchodzi się z danymi osobowymi użytkownika w ograniczonych okolicznościach, w których musimy gromadzić dane osobowe, np. podjąć u nas pracę, starać się inwestować lub pracować z Laava (jako przedstawiciel partnera, klienta, dostawcy lub usługodawcy), lub jeśli skontaktujesz się z naszym działem pomocy lub prześlesz zapytanie.",
  privacyPolicyP4:
    "Określa, jakie dane osobowe gromadzimy na Twój temat, kiedy i jak je wykorzystujemy. Określa również Twoje prawa do prywatności jako osoby fizycznej oraz sposób kontaktowania się z nami w przypadku jakichkolwiek obaw związanych z prywatnością. Niniejsza Polityka prywatności może od czasu do czasu ulec zmianie, dlatego warto ją regularnie przeglądać.",
  privacyPolicyP5:
    "Jak określono w naszym Manifeście dotyczącym prywatności, nie gromadzimy żadnych danych osobowych w związku ze skanowaniem odcisku palca Laava.",
  privacyPolicyP6:
    "Nie widzimy również ani nie gromadzimy Twoich danych osobowych, gdy zdecydujesz się udostępnić dane osobowe właścicielom marek korzystających z naszych odcisków palców Laava (marki). Jest to również omówione w naszym Manifeście dotyczącym prywatności.",
  privacyPolicyP7:
    "Istnieją jednak pewne ograniczone okoliczności, w których musimy zbierać Twoje dane osobowe bezpośrednio od Ciebie, w tym gdy:",
  privacyPolicyP8:
    "komunikować się z nami przez telefon, e-mail, online (w tym za pośrednictwem naszej strony internetowej lub działu pomocy) lub osobiście",
  privacyPolicyP9: "kiedy ubiegasz się o stanowisko u nas",
  privacyPolicyP10: "kiedy ubiegasz się o zostanie inwestorem; Lub",
  privacyPolicyP11:
    "gdy wchodzisz z nami w interakcję jako potencjalny lub stały klient, partner, dostawca lub usługodawca.",
  privacyPolicyP12:
    "Dane osobowe to wszelkie informacje lub opinie (prawdziwe lub nie, zapisane w formie materialnej lub nie) na temat użytkownika jako osoby zidentyfikowanej lub osoby, którą można rozsądnie zidentyfikować. Rodzaje danych osobowych, które zbieramy i przetwarzamy, mogą obejmować:",
  privacyPolicyP13: "dane kontaktowe, takie jak imię i nazwisko, adres, numer telefonu, adres e-mail i/lub adres IP",
  privacyPolicyP14:
    "informacje o Twojej roli w Twojej organizacji, jeśli jesteś naszym obecnym lub potencjalnym partnerem, klientem, dostawcą lub usługodawcą",
  privacyPolicyP15:
    "dane finansowe i inne istotne informacje, które nam udostępniasz, jeśli składasz wniosek o bycie inwestorem",
  privacyPolicyP16:
    "jeśli ubiegasz się u nas o stanowisko, możemy zbierać informacje o twoich kwalifikacjach, doświadczeniu w zakresie umiejętności, charakterze, kontrolach weryfikacyjnych i historii zatrudnienia; I",
  privacyPolicyP17:
    "inne informacje, które przekazujesz nam za pośrednictwem naszego działu pomocy technicznej (lub w jakimkolwiek zapytaniu, które do nas wysyłasz).",
  privacyPolicyP18:
    "Gromadzimy, przechowujemy, wykorzystujemy i ujawniamy dane osobowe przede wszystkim w celu udzielenia odpowiedzi na zapytania, rozpatrzenia Twojej aplikacji na stanowisko u nas lub uznania Cię za naszego inwestora, klienta, dostawcę lub partnera. Możemy również gromadzić Twoje dane osobowe z powiązanych powodów, w tym:",
  privacyPolicyP19: "cele administracyjne, w tym prowadzenie dokumentacji i raportowanie wewnętrzne",
  privacyPolicyP20: "komunikować się z tobą",
  privacyPolicyP21: "celach marketingowych; I",
  privacyPolicyP22: "w celu zachowania zgodności z obowiązującymi przepisami prawa i regulacjami.",
  privacyPolicyP23:
    "W ograniczonych okolicznościach wymienionych w sekcji 2 powyżej, w których zgromadziliśmy Twoje dane osobowe, możemy ujawnić Twoje dane osobowe stronom trzecim. Rodzaje stron trzecich, z którymi wymieniamy dane osobowe, obejmują:",
  privacyPolicyP24:
    "nasi dostawcy usług, w tym usługi księgowe, audytowe, prawne, bankowe, płatności, dostawy, hosting danych, badania, rekrutacja, ubezpieczenia, emerytury, listy płac, szkolenia i usługi technologiczne",
  privacyPolicyP25:
    "dla osób, które ubiegają się o pracę u nas, sędziów i dostawców kontroli przesiewowych (np. w celu sprawdzenia przeszłości, uprawnień do pracy, przydatności i kontroli karalności); I",
  privacyPolicyP26: "organom ścigania i innym organizacjom, jeśli jest to wymagane lub dozwolone przez prawo.",
  privacyPolicyP27:
    "Nie ujawnimy Twoich danych osobowych odbiorcom zagranicznym. Możemy jednak przechowywać Twoje dane osobowe u dostawców bezpiecznego hostingu danych, takich jak Google, którzy mogą przechowywać te informacje w następujących lokalizacjach: ({{link}}). W miarę możliwości będziemy wymagać, aby Twoje dane osobowe były przechowywane w Australii. Jeśli będziemy musieli ujawnić Twoje dane osobowe odbiorcom zagranicznym, zaktualizujemy niniejszą Politykę prywatności, określając kraje, w których się znajdują.",
  privacyPolicyP28: "Jeśli nie podasz nam danych osobowych, gdy jest to wymagane, możemy nie być w stanie:",
  privacyPolicyP29: "rozpatrzyć Twoją aplikację, aby dołączyć do nas jako pracownik",
  privacyPolicyP30: "rozważ swoją aplikację, aby zostać inwestorem, klientem, partnerem; dostawca lub usługodawca; I",
  privacyPolicyP31: "odpowiedzieć na Twoje zapytania lub prośby o informacje.",
  privacyPolicyP32:
    "Plik cookie przeglądarki to mały fragment danych przechowywany na Twoim urządzeniu, aby pomóc stronom internetowym i aplikacjom mobilnym zapamiętać informacje o Tobie. Chociaż nie używamy plików cookie w aplikacji mobilnej Laava, używamy plików cookie na naszej stronie internetowej. Pliki cookie pomagają nam zobaczyć, które części naszej witryny są najpopularniejsze, co pozwala nam skupić się na ulepszaniu tych aspektów witryny, które poprawią Twoje wrażenia i zapewnią lepsze, bardziej intuicyjne i satysfakcjonujące doświadczenie. Twoja przeglądarka lub urządzenie może umożliwiać blokowanie lub w inny sposób ograniczać korzystanie z plików cookie. Możesz także mieć możliwość usunięcia plików cookie z przeglądarki.",
  privacyPolicyP33:
    "Aby pomóc w ulepszeniu usług, które świadczymy za pośrednictwem naszej witryny, niektóre informacje mogą być zbierane z Twojej przeglądarki. Informacje te są gromadzone jako informacje statystyczne i obejmują adres IP, typ przeglądarki, język i czas dostępu. W wielu przypadkach narzędzia używane na naszej stronie zapisują informacje w sposób, który nie identyfikuje Cię jako osoby fizycznej. W innych przypadkach informacje mogą być danymi osobowymi, w którym to przypadku niniejsza Polityka prywatności będzie miała zastosowanie.",
  privacyPolicyP34:
    "Nasza witryna internetowa może również od czasu do czasu korzystać z narzędzi stron trzecich, na przykład Google Analytics i narzędzi do ankiet online. Aby uzyskać więcej informacji o tym, jak Google gromadzi i przetwarza dane, zapoznaj się z polityką prywatności Google i ich informacjami pod adresem: ({{ link }}).",
  privacyPolicyP35:
    "Podejmujemy wszelkie uzasadnione kroki, aby chronić dane osobowe przed utratą, ingerencją, niewłaściwym wykorzystaniem lub nieautoryzowanym dostępem, modyfikacją lub ujawnieniem. Kroki te mogą obejmować kontrolę dostępu, szyfrowanie i zabezpieczanie pomieszczeń. Przechowujemy dane osobowe zarówno w formie fizycznej, jak i elektronicznej, czasami z pomocą stron trzecich, takich jak dostawcy hostingu danych.",
  privacyPolicyP36:
    "Od czasu do czasu, jeśli wyraziłeś zgodę na otrzymywanie od nas informacji marketingowych, możemy wysyłać Ci bezpośrednie komunikaty marketingowe dotyczące naszych usług.",
  privacyPolicyP37:
    "Możemy kontaktować się z Tobą za pomocą wiadomości elektronicznych (np. e-mail), online (np. za pośrednictwem naszej strony internetowej), pocztą i innymi środkami, chyba że później zrezygnujesz lub podlegamy ograniczeniom prawnym. Możesz zrezygnować z komunikacji marketingowej, kontaktując się z nami, korzystając z poniższych danych kontaktowych.",
  privacyPolicyP38:
    "Dokładność danych osobowych, które przechowujemy i wykorzystujemy, jest dla nas ważna. Podejmujemy uzasadnione kroki, aby zapewnić, że przetwarzane przez nas dane osobowe są dokładne, kompletne i aktualne. Aby pomóc nam zachować dokładność Twoich danych osobowych, daj nam znać, jeśli są jakieś błędy lub zmiany w Twoich danych osobowych.",
  privacyPolicyP39:
    "W każdej chwili możesz zażądać dostępu do danych osobowych, które przechowujemy na Twój temat, i ich poprawienia, kontaktując się z naszym Specjalistą ds. Prywatności, korzystając z danych kontaktowych na dole niniejszej Polityki prywatności. W większości przypadków możemy pomóc Ci szybko i nieformalnie w tych prośbach. W innych przypadkach możemy potrzebować zweryfikować Twoją tożsamość i poprosić Cię o złożenie wniosku na piśmie.",
  privacyPolicyP40:
    "Możesz skontaktować się z naszym Specjalistą ds. Prywatności, korzystając z danych kontaktowych na dole niniejszej Polityki prywatności, jeśli masz jakiekolwiek wątpliwości dotyczące sposobu, w jaki postępowaliśmy z Twoimi danymi osobowymi. Odpowiemy, aby poinformować Cię, kto zajmie się Twoją sprawą i kiedy możesz spodziewać się dalszej odpowiedzi. Możemy poprosić Cię o dodatkowe szczegóły dotyczące Twojego problemu i być może będziemy musieli zaangażować lub skonsultować się z innymi stronami w celu zbadania i rozwiązania Twojego problemu. Będziemy przechowywać zapisy Twojej prośby i wszelkich rozwiązań. Jeśli nie jesteś zadowolony ze sposobu, w jaki rozpatrzyliśmy Twoją skargę, możesz skontaktować się z Biurem Australijskiego Komisarza ds. Informacji.",
  privacyPolicyP41:
    "Jeśli masz jakiekolwiek pytania, skargi lub roszczenia dotyczące Skanera Witryny lub niniejszych Warunków, możesz skontaktować się z Laava pod adresem:",
  problemsTitle: "Problemy z odciskiem palca",
  problemsIntro:
    "Jeśli Laava Smart Fingerprint™ wygląda na uszkodzony lub masz problemy ze skanowaniem, możesz skontaktować się z nami, a my dołożymy wszelkich starań, aby pomóc Ci rozwiązać problem.",
  tryScanningAgain: "Spróbuj ponownie zeskanować",
  emailUs: "Wyślij do nas e-mail",
  troubleDetectingFingerprint: "Mamy problem z wykryciem odcisku palca",
  makeSureFingerprint: "Upewnij się, że odcisk palca jest dobrze oświetlony i ostry",
  ensureInternet: "Upewnij się, że połączenie internetowe jest dobre",
  tryAgain: "Spróbuj ponownie",
  useLegacyScanner: "Użyj skanera legacy",
  logIn: "Zaloguj sie",
  account: "Konto",
  emailAddress: "Adres e-mail",
  password: "Hasło",
  submit: "Składać",
  thereWasAProblemLoggingIn: "Wystąpił problem podczas logowania. Spróbuj ponownie.",
  loggedInAs: "Zalogowany jako",
  logOut: "Wyloguj",
}

export default pl
