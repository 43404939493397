const fr = {
  pageTitle: "Laava Scanner",
  pageDescription: "Scannez votre Laava Smart Fingerprint™ pour vous assurer que votre produit est authentique.",
  loading: "Chargement",
  startingScanner: "Démarrage du scanner",

  privacyPolicy: "En utilisant Laava, vous acceptez notre",
  cookiesPolicy: "Politique de Cookies",
  privacyAndCookies: "Confidentialité & Cookies",
  termsOfUse: "Conditions d’utilisation",
  accept: "Accepter",
  copyrightContent:
    "LAAVA, SMART FINGERPRINTS, FINGERPRINTS, AUTHENTICATION OF THINGS, le tick Laava, le logo Laava et LAAVA sont des marques de commerce de Laava ID Pty Ltd.",

  titleWarningAllowCamera: "Veuillez autoriser l’accès à la caméra.",
  bodyWarningAllowCamera:
    "Ce scanner utilise l’appareil photo pour numériser les empreintes digitales. Autorisez l’accès à la caméra pour réessayer.",

  titleWarningSupportedBrowser: "Veuillez ouvrir {{browser}} pour scanner votre empreinte digitale.",
  bodyWarningSupportedBrowser: "Le navigateur que vous utilisez n’est actuellement pas pris en charge.",

  activateTheCameraOn: "Activez la caméra sur {{device}}.",
  thisWebscannerUsesTheCamera: "Ce scanner utilise l’appareil photo pour numériser les empreintes digitales.",

  scanNow: "Analyser maintenant",
  refresh: "Rafraîchir",
  refreshThePage: "Actualiser la page",
  loadAgain: "Chargez scan.laava.id à nouveau.",
  selectAllowWhenAsked: "Sélectionnez Autoriser lorsque vous êtes invité à accéder à la caméra",
  whenAskedSelectAllow: "Lorsqu’on vous demande scan.laava.id Souhaitez accéder à la caméra, sélectionnez Autoriser.",

  positionTheCode: "Positionnez le code dans le cadre à analyser",

  moveCloserToQR: "Rapprochez-vous du code QR",
  moveFurtherFromQR: "Éloignez-vous du code QR",
  centerQRCode: "Centrez le code QR",
  unblurQrCode: "Le code QR est trop flou",
  goodQrCode: "Bon code QR",
  badQrCode: "Aucun code QR détecté",
  qrCodeError: "Erreur de code QR",

  seeSupportedBrowsers: "Lancer le navigateur",

  supportedBrowsersPageTitle: "Ce scanner nécessite un navigateur avec prise en charge de l’appareil photo.",
  supportedBrowsersPageBody:
    "Le système d’exploitation de votre appareil déterminera quels navigateurs Web prennent en charge la caméra.",
  howUnblockCamera: "Comment débloquer l’appareil photo",
  help: "Aide",
  supportedBrowsersPageHelpBody: "Si vous avez des questions ou des préoccupations, veuillez communiquer avec nous.",
  contactUs: "Contactez-nous",

  goToBrowserSettings: "Accédez aux paramètres du navigateur",
  findSettingBrowser:
    "Vous pouvez trouver les paramètres en appuyant sur les trois points dans le coin supérieur droit du navigateur.",
  selectSiteSettings: "Sélectionner les paramètres du site",
  foundAdvanced: "Cela peut être trouvé sous la sous-rubrique avancée.",
  selectCamera: "Sélectionner la caméra",
  selectWebsite: "Sélectionnez 'https://scan.laava.id'",
  changePermission: "Modifiez l’autorisation d’accéder à votre caméra pour l’autoriser.",
  returnToWebscanner: "Revenez au scanner pour analyser.",

  lookForLaava: "Cherchez Laava.",
  simplyScan: "Il suffit de scanner.",
  shopWithAssurance: "Achetez avec assurance.",
  laavaEnablesBrands:
    "Laava permet aux marques de vous donner l’assurance de la qualité de leurs produits en utilisant une nouvelle technologie sécurisée développée avec le CSIRO.",

  aboutLaava: "À propos de Laava",
  knowYouReGetting: "Sachez que vous obtenez le vrai produit et non un substitut",
  laavaIsANewKind:
    "Laava est un nouveau type de code-barres qui peut aider à s’assurer que le produit pour lequel vous payez est la vraie chose et vous protéger des imitations de qualité inférieure.",

  everyFingerprint: "Chaque empreinte digitale est complètement unique et ne ressemble à aucune autre",
  aFingerprintDoes:
    "Une empreinte digitale fait bien plus que simplement indiquer quel produit ou lot vous détenez. C’est une empreinte digitale individuelle et complètement unique pour le produit exact dans votre main.",

  seeBeyondTheLabel: "Voir au-delà de l’étiquette et en savoir plus sur vos produits",
  scanningAllowsYou:
    "La numérisation vous permet de voir plus d’informations que ce qui se trouve sur l’étiquette. Renseignez-vous sur des informations spécifiques sur cet article exact, par exemple l’endroit où il a été produit ou le moment où il a été emballé.",

  ourCompany: "Notre entreprise",
  laavaWasFounded:
    "Laava a été fondée en Australie en 2016. Le concept et la solution technologique ont été développés par des experts mondiaux de premier plan dans le domaine de l’optique, des blockchains, du marketing et des connaissances des consommateurs.",

  needHelpScanning: "Besoin d’aide pour la numérisation?",

  youCanFindDetails:
    "Vous trouverez des détails sur l’analyse, ainsi que des réponses aux problèmes courants, dans notre guide d’aide.",

  getHelp: "Obtenir de l’aide sur l’analyse",

  howScan: "Comment numériser",
  problemsWithTheFingerprint: "Problèmes avec l’empreinte digitale",
  commonProblems: "Problèmes courants",
  getResults:
    "Pour obtenir les meilleurs résultats, ces conseils peuvent vous aider à surmonter certains problèmes courants qui peuvent affecter la qualité de votre analyse.",
  readyToScan: "Prêt à scanner une empreinte digitale?",
  needMoreHelp: "Besoin de plus d’aide?",
  scanFingerprint: "Scannez votre empreinte digitale",
  bringFingerprint:
    "Mettez l’empreinte digitale au point dans la vue de l’appareil photo et maintenez-la en place jusqu’à ce que l’empreinte digitale soit détectée. Cela peut prendre quelques instants en fonction de votre vitesse de connexion.",

  locateSmartFingerprint: "Localisez votre empreinte digitale intelligente™",
  fingerprintsCanBeFound:
    "Les empreintes digitales peuvent être trouvées sur une variété de produits de tous les jours.",
  alignTheFingerprint: "Aligner l’empreinte digitale à l’aide du guide",
  alignTheGuide: "Alignez le guide sur les anneaux intérieur et extérieur de l’empreinte digitale.",
  snapPhoto: "Prendre une photo",
  getYourFingerprint: "Concentrez votre empreinte digitale et gardez votre main stable.",

  light: "Lumière",
  likeAnyCamera:
    "Comme tout appareil photo, les meilleurs résultats sont sous un bon jour. Assurez-vous que votre empreinte digitale est bien éclairée et surveillez l’éblouissement.",
  keepYourHands: "Gardez vos mains stables",
  tryKeepYourHand:
    "Essayez de garder votre main et votre appareil photo immobiles lorsque vous prenez la photo, afin que l’image soit claire.",
  considerTheAngle: "Considérez l’angle",
  scanYourFingerprint:
    "Scannez votre empreinte digitale dans une position située à l’avant de l’appareil photo et remplissez la majeure partie de la vue (tout en restant au point).",
  watchOutForShadow: "Méfiez-vous des ombres",
  whenScanning:
    "Lors de la numérisation, assurez-vous que votre téléphone ne projette pas une ombre sombre sur l’empreinte digitale.",

  haveAnyQuestion: "Vous avez des questions ou des commentaires? Nous aimerions avoir de vos nouvelles.",
  email: "Messagerie électronique",

  startingCamera: "Caméra de démarrage",
  searching: "Balayage",
  analysing: "Analyse",
  sending: "Envoi",
  sendingForVerification: "Envoi pour vérification",
  mightTakeAFewMoments: "Cela peut prendre quelques instants",
  placeCodeWithinFrame: "Placez le code dans le cadre à analyser",
  needHelp: "Besoin d’aide?",

  googlePrivacyPolicy: "Politique de confidentialité de Google",
  laavaPrivacy: "Laava Confidentialité",
  laavaHasAPrivacy: `Laava a un {{ manifesto }} qui explique nos politiques qui vous 
                        affectent et affectent votre vie privée en tant qu’utilisateur
                        de Laava Fingerprints. Séparément, nous avons également une
                        {{ policy }} qui ne s’applique que dans les circonstances limitées
                        dans lesquelles nous devons collecter des informations personnelles.`,
  privacyManifesto: "Manifeste sur la protection de la vie privée",
  corporatePrivacy: "Politique de confidentialité de l’entreprise",

  laavaWebscannerTerm: "Laava – Termes et conditions de Webscanner",
  general: "Généralités",
  websiteUse: "Utilisation du site Web",
  intellectualProperty: "Propriété intellectuelle",
  limitionOfLiability: "Limitation de responsabilité",
  indemnity: "Indemnité",
  otherWebsites: "Autres sites Web et tiers",
  entireAgreement: "Intégralité de l’accord",
  invalidity: "Invalidité et force exécutoire",
  privacy: "Vie privée",
  applicableLaw: "Droit applicable",
  howToContact: "Comment nous contacter",

  termsP00: `Merci d’avoir accédé au site Web de Laava (le site Web). Le site Web est 
                détenu et exploité par Laava ID Pty Ltd (A.B.N. 99 617 775 578) (Laava).`,

  termsP01: `Votre utilisation du site Web est régie par les présentes conditions 
                d’utilisation. En accédant au site Web et en l’utilisant, vous
                acceptez d’être lié par les présentes conditions d’utilisation.`,

  termsP02: `Laava peut modifier tout ou partie de ces Conditions d’utilisation à tout
                 moment. Les conditions d’utilisation les plus récentes seront toujours
                 affichées sur le site Web. Votre utilisation ultérieure ou continue du
                 site Web constituera votre acceptation de toute modification apportée
                 aux présentes conditions d’utilisation. Si vous vous opposez à ces
                 conditions d’utilisation ou à toute modification qui y est apportée,
                 vous devez cesser d’utiliser le site Web.`,

  termsP03: "Ces conditions ont été mises à jour pour la dernière fois le 26 novembre 2018.",

  termsP1: "Vous ne pouvez utiliser le site Web ou tout contenu qu’à des fins non commerciales.",

  termsP2: "Les informations contenues sur le site Web sont fournies à titre d’information générale uniquement.",

  termsP3: `Le site Web et les informations, liens, documents ou graphiques connexes 
            contenus sur le site Web (ensemble, le contenu) sont fournis 'tels quels'.
            Toute confiance que vous accordez au Contenu est strictement à vos propres
            risques. Vous devez faire vos propres recherches pour déterminer si le
            Contenu est approprié pour l’utilisation prévue.`,

  termsP4: `Bien que Laava fasse tous les efforts raisonnables pour s’assurer que le 
                site Web est disponible et pleinement opérationnel à tout moment, votre
                accès au site Web peut être suspendu sans préavis en cas de défaillance
                du système, de maintenance ou de toute raison indépendante de la volonté
                de Laava.`,

  termsP5: "Laava peut modifier ou interrompre, temporairement ou définitivement:",

  termsP6: "Site Web, ou tout contenu, fonctionnalité ou service sur le site Web; ou",

  termsP7: "le chemin ou l’emplacement d’un lien,",

  termsP8: `à tout moment et sans préavis. Laava décline toute responsabilité en relation 
             avec ces changements, interruptions ou interruptions similaires du site Web ou
             de toute partie du site Web.`,

  termsP9: `Laava se réserve le droit de mettre fin à votre accès au site Web et d’empêcher 
                tout accès ultérieur sans avertissement ni préavis en réponse à des violations
                des présentes conditions d’utilisation par vous-même, à des violations de toute
                loi applicable ou à toute activité suspecte.`,

  termsP10: "Lorsque vous utilisez le site Web, vous acceptez de ne pas:",

  termsP11: "fournir toute information fausse, trompeuse ou trompeuse;",

  termsP12: "utiliser le site Web d’une manière qui viole, ou qui peut enfreindre, toute loi ou réglementation;",

  termsP13: "utiliser le site Web d’une manière qui peut amener Laava à enfreindre toute loi ou réglementation;",

  termsP14: "utiliser le site Web d’une manière qui reflète défavorablement Laava ou tout autre tiers;",

  termsP15:
    "recueillir des informations sur d’autres utilisateurs du site Web à des fins autres que celles expressément autorisées par nous;",

  termsP16:
    "prendre toute mesure qui, de l’avis de Laava, impose un fardeau déraisonnable à l’infrastructure du site Web;; ",

  termsP17:
    "distribuer un virus informatique ou tout programme ou code similaire susceptible de perturber ou de désactiver le site Web;",

  termsP18: `utiliser un appareil, un logiciel ou un processus, 
                ou d’autres moyens automatisés pour récupérer, gratter,
                copier ou indexer le site Web ou tout contenu à quelque
                fin que ce soit; ou`,

  termsP19: `récupérer, gratter, copier ou indexer le site Web ou tout 
                contenu dans le but de créer une base de données
                d’informations immobilières.`,

  termsP20: `Le site Web et toute la propriété intellectuelle du 
            contenu du site Web, y compris (sans s’y limiter) tout
            texte, graphique, photographie, logo ou marque de commerce,
            sont la propriété de Laava ou lui sont concédés sous licence,
            sauf indication contraire.`,

  termsP21: `Sauf lorsque cela est nécessaire pour visualiser le contenu 
                du site Web, lorsque la loi le permet ou lorsque vous avez
                obtenu l’autorisation écrite expresse de Laava, vous ne pouvez
                pas reproduire, recompiler, décompiler, désassembler, désosser,
                créer des œuvres dérivées, exécuter, modifier, adapter, publier,
                stocker, utiliser, transmettre électroniquement ou autrement,
                distribuer ou exploiter de toute autre manière, par quelque
                moyen que ce soit, en tout ou en partie, le site Web, ou le
                Contenu du Site Web à des fins autres que votre propre usage
                personnel et non commercial.`,

  termsP22: "Votre utilisation du site Web est effectuée à vos propres risques.",

  termsP23: `Dans toute la mesure permise par la loi, Laava décline toute 
                représentation, garantie ou garantie, expresse ou implicite,
                y compris, mais sans s’y limiter:`,

  termsP24: `l’exhaustivité, l’exactitude, la fiabilité, la pertinence ou la 
                disponibilité en ce qui concerne le site Web ou le contenu;`,

  termsP25: `que le site Web et le contenu seront exempts d’erreurs ou que les 
                défauts seront corrigés;`,

  termsP26: "que votre accès au site Web sera ininterrompu, sécurisé ou sans erreur;",

  termsP27: "que le site Web est exempt de virus ou de tout autre composant nuisible; et",

  termsP28: "la qualité, l’exactitude ou l’adéquation à l’usage de tout site Web lié ou de matériel de tiers.",

  termsP29: `Dans toute la mesure permise par la loi, Laava et ses administrateurs, 
                employés et agents, ne sont pas responsables envers vous de toute perte
                ou dommage de quelque nature que ce soit (y compris les pertes consécutives)
                quelle qu’en soit la cause, que ce soit par contrat, délit, y compris la
                négligence, la loi ou autrement découlant du site Web ou du contenu.`,

  termsP30: `Rien dans les présentes Conditions d’utilisation n’est destiné à exclure, 
                restreindre ou modifier les droits que vous pourriez avoir en vertu d’une
                loi (y compris en Australie, la loi australienne sur la consommation) qui
                ne peuvent être exclus, restreints ou modifiés par accord. Si les présentes
                conditions d’utilisation sont régies par la loi australienne sur la consommation,
                notre responsabilité envers vous en cas de non-respect d’une garantie du
                consommateur à l’égard de tout service est limitée à la fourniture à nouveau des
                services ou au paiement d’une telle réapprovisionnement.`,

  termsP31: `Vous indemnisez Laava à l’égard de toute responsabilité encourue par Laava pour 
                toute perte, responsabilité ou dommage, dépense, y compris, mais sans s’y
                limiter, les frais juridiques et comptables raisonnables, quelle qu’en soit la
                cause, qui sont subis par Laava à la suite de votre violation des présentes
                Conditions d’utilisation ou de votre utilisation du site Web.`,

  termsP32: `Le site Web peut contenir des liens vers des sites Web de tiers, des publicités 
                ou des informations sur ces sites Web.`,

  termsP33: `Laava n’a aucun contrôle sur la nature, le contenu et la disponibilité des sites 
                Web de tiers. L’inclusion de liens vers d’autres sites Web n’implique pas
                nécessairement un parrainage, une approbation ou une approbation de ces sites
                Web ou des opinions exprimées en leur sein.`,

  termsP34: `Laava n’assume aucune responsabilité pour le contenu de ces sites Web. Toute 
                utilisation de sites Web et d’informations de tiers est à vos propres risques.`,

  termsP35: `Les présentes Conditions d’utilisation représentent l’intégralité de l’accord 
                entre vous et Laava concernant votre utilisation et votre accès au Site Web
                ainsi que votre utilisation et votre accès au Contenu qu’il contient. Aucun
                autre terme ne doit être inclus dans les présentes Conditions d’utilisation,
                sauf s’il est requis pour être inclus par une législation du Commonwealth
                d’Australie ou d’un État ou territoire d’Australie. Tous les termes implicites,
                à l’exception de ceux implicites par la loi et qui ne peuvent être expressément
                exclus, sont expressément exclus.`,

  termsP36: `Si une disposition des présentes Conditions d’utilisation est invalide en vertu 
                de la loi d’une juridiction, la disposition est exécutoire dans cette juridiction
                dans la mesure où elle n’est pas invalide, qu’elle soit en termes divisibles ou non.`,

  termsP37: `Toutes les informations personnelles que Laava recueille auprès de vous seront 
                traitées conformément à la Loi sur la protection de la vie privée de 1988 (Cth)
                et à la {{policy}}`,

  termsP38: `Pour plus d’informations sur la façon dont Laava traite vos informations personnelles 
                lorsque vous scannez les empreintes digitales de Laava, reportez-vous à {{manifesto}}`,

  termsP39: `Laava se réserve le droit, à tout moment et de temps à autre, de modifier, d’interrompre 
                temporairement ou définitivement le scanner de site Web, ou toute partie de celui-ci,
                pour quelque raison que ce soit. Laava décline toute responsabilité à la suite de toute
                interruption ou interruption du scanner du site Web, ou de toute partie de celui-ci`,

  termsP40: "Vous pouvez contacter Laava via:",

  contact: "Contact",
  privacyOfficer: "Responsable de la protection de la vie privée",
  address: "Adresse",
  phone: "Téléphone",

  laavaPrivacyManifesto: "Manifeste de la protection de la vie privée de Laava",
  privacyManifestoIntroduction: "Laava – Manifeste sur la protection de la vie privée",
  ourApproach: "Notre approche de votre vie privée",
  whatInformationScan: "Quelles informations sont collectées lorsque je scanne une empreinte digitale Laava?",
  whatInformationDetect: "Quelles informations sont collectées lorsque nous détectons une activité suspecte?",
  howDoesLaava: "Comment Laava utilise-t-elle les cookies?",
  sharingInformation: "Partage d’informations",

  privacyManifestoP1: `Ce document est notre Manifeste de confidentialité qui décrit notre politique 
                        concernant la collecte d’informations lorsque vous scannez une empreinte digitale
                        intelligente Laava à l’aide d’un navigateur ou d’un scanner WeChat Laava.`,

  privacyManifestoP2: `Nous avons un {{ link }} distinct qui définit comment nous traitons vos informations 
                        personnelles dans d’autres circonstances commerciales, par exemple si vous postulez
                        pour un poste chez nous, si vous êtes un investisseur potentiel, un représentant
                        d’un partenaire, client, fournisseur ou fournisseur de services, ou si vous contactez
                        notre service d’assistance ou soumettez une question ou une requête avec nous.`,

  privacyManifestoP3: `Nous ne souhaitons pas collecter vos informations personnelles lorsque vous scannez 
                        Les empreintes digitales intelligentes de Laava. Le respect de votre vie privée est
                        notre principale préoccupation, c’est pourquoi nous n’exigeons pas de noms, de
                        connexions, de numéros de téléphone, d’e-mails ou de toute autre information vous
                        identifiant personnellement.`,

  privacyManifestoP4: `La numérisation des empreintes digitales intelligentes Laava est au cœur de tout ce 
                        que nous faisons. Nous voulons fournir un environnement sûr, privé et sans souci pour
                        tous ceux qui scannent une empreinte digitale intelligente Laava. Scanner les
                        empreintes digitales intelligentes Laava signifie pas de tracas et pas d’indiscrets.`,

  privacyManifestoP5: `Nous pensons que tant d’entreprises peuvent faire mieux en ce qui concerne la vie 
                        privée des consommateurs et nous voulons montrer la voie. Ce n’est pas parce que
                        vous pouvez collecter des données que vous devriez le faire.`,

  privacyManifestoP6: `Lorsque vous numérisez une empreinte digitale intelligente Laava, nous capturons une 
                        image de votre numérisation et d’autres informations de numérisation, ou métadonnées,
                        nécessaires pour authentifier l’empreinte digitale intelligente Laava et mettre à jour
                        son enregistrement d’état. Ces métadonnées peuvent inclure l’URL d’où provient l’analyse,
                        l’emplacement de l’analyse (généralement dérivé d’une adresse IP ou d’une position GPS)
                        et l’heure de chaque analyse. Nous attribuons et enregistrons également un identifiant
                        de scanner anonymisé à chaque numérisation et pouvons utiliser des informations limitées
                        sur l’appareil, telles que les paramètres du navigateur et de l’appareil photo.`,

  privacyManifestoP7: `Il est important de noter que toutes les informations que nous recueillons lorsque vous 
                        scannez une empreinte digitale intelligente Laava sont anonymisées et ne peuvent pas être
                        facilement utilisées pour vous identifier (informations anonymisées). Si l’image de votre
                        scan contient des informations qui pourraient être utilisées pour vous identifier, nous ne
                        chercherons pas à le faire.`,

  privacyManifestoP8: `Si vous scannez une empreinte digitale intelligente Laava dans notre mini-programme WeChat, 
                        vous serez également soumis à la politique de confidentialité de WeChat.`,

  privacyManifestoP9: `Pour nous assurer que Laava est un outil efficace contre la contrefaçon et la fraude, et 
                        pour assurer la sécurité de votre environnement de numérisation et maintenir une haute qualité
                        de service, de performance et de sécurité, nous utilisons une gamme de méthodes pour déterminer
                        si l’activité de numérisation d’une empreinte digitale intelligente Laava individuelle est
                        suspecte. Lorsque nous détectons une activité suspecte, nous pouvons effectuer une analyse
                        approfondie de l’activité d’analyse et des métadonnées, ainsi que transmettre ces informations
                        à nos clients et partenaires (ensemble les clients).`,

  privacyManifestoP10: `Laava utilise des cookies de navigateur sur son site Web. Un cookie de navigateur est un petit 
                        morceau de données qui est stocké sur votre appareil pour aider les sites Web à se souvenir de
                        choses sur votre navigation. Les cookies nous aident à voir quelles parties de notre site Web
                        sont les plus populaires, ce qui nous permet d’améliorer votre expérience.`,

  privacyManifestoP11: `Pour aider à améliorer notre site Web, certaines informations peuvent être collectées sous 
                        forme d’informations statistiques et peuvent inclure l’adresse IP, le type de navigateur, la
                        langue et les temps d’accès. Les outils utilisés sur notre site Web enregistrent des informations
                        d’une manière qui ne vous identifie pas en tant qu’individu. Votre navigateur ou votre appareil
                        peut vous permettre de bloquer, de limiter l’utilisation ou de supprimer les cookies.`,

  privacyManifestoP12: `Lorsque vous numérisez Laava Smart Fingerprints à l’aide d’un scanner basé sur un navigateur, 
                        des cookies peuvent être utilisés, mais votre historique de numérisation ne sera pas stocké.`,

  privacyManifestoP13: `Notre site Web peut également utiliser des outils de site Web tiers de temps à autre, par 
                        exemple Google Analytics et des outils d’enquête en ligne. Pour en savoir plus sur la façon
                        dont Google collecte et traite les données, veuillez consulter la politique de confidentialité
                        de Google et leurs informations à l’adresse {{ link }}`,

  privacyManifestoP14: `Les clients sont évidemment intéressés par l’analyse des enregistrements de numérisation 
                        Laava Smart Fingerprints associés à leurs articles. Nous partageons des informations de
                        numérisation sur les empreintes digitales intelligentes Laava avec les clients, généralement
                        sous une forme agrégée. Lorsque nous partageons des informations avec les clients, nous leur
                        disons uniquement ce qu’ils doivent savoir, par exemple où, quand et combien de fois leur
                        empreinte digitale intelligente Laava a été numérisée. Étant donné que nous ne recueillons
                        pas vos renseignements personnels, nous ne pouvons pas les partager avec eux.`,

  privacyManifestoP15: `Nous permettons également aux clients de s’engager avec vous sur une base personnelle lorsque 
                        vous scannez une empreinte digitale laava smart. Dans le cadre de cet engagement, les Clients
                        peuvent vous demander des informations personnelles. Nous pensons que c’est génial, mais chez
                        Laava, le partage de toute information personnelle se fait toujours sur une base transparente
                        et opt-in. Vous êtes toujours en charge et en mesure de choisir ce que vous partagez.`,

  privacyManifestoP16: `Laava ne verra ni ne collectera vos informations personnelles, mais toute information 
                        personnelle que vous partagez sera collectée et traitée par nos clients conformément à
                        leurs politiques de confidentialité, qu’il vous sera demandé d’accepter.`,

  privacyManifestoP17: `Laava peut partager toute information que vous fournissez lorsqu’elle estime que la divulgation 
                        est appropriée pour se conformer à la loi, pour faciliter les procédures judiciaires ou protéger
                        les droits, la propriété ou la sécurité de Laava, des utilisateurs de ce site Web et d’autres.`,

  laavaPrivacyPolicy: "Politique de confidentialité de Laava",
  privacyPolicyT1: "À propos de cette politique de confidentialité d’entreprise",
  privacyPolicyT2: "Quand recueillons-nous vos renseignements personnels?",
  privacyPolicyT3: "Quels renseignements personnels recueillons-nous et traitons-nous?",
  privacyPolicyT4: "Pourquoi recueillons-nous vos renseignements personnels?",
  privacyPolicyT5: "À qui pourrions-nous divulguer vos renseignements personnels?",
  privacyPolicyT6: "Que se passe-t-il si nous ne pouvons pas recueillir vos renseignements personnels?",
  privacyPolicyT7: "Visiteurs de notre site Web et cookies",
  privacyPolicyT8: "Comment protégeons-nous vos renseignements personnels?",
  privacyPolicyT9: "Communications de marketing direct",
  privacyPolicyT10: "Comment pouvez-vous accéder à vos renseignements personnels et les corriger?",
  privacyPolicyT11: "Comment pouvez-vous déposer une plainte relative à la protection de la vie privée?",
  privacyPolicyT12: "Comment nous contacter",

  privacyPolicyP1: `Nous pensons que tant d’entreprises peuvent faire mieux en ce qui concerne la vie privée des 
                    consommateurs et nous voulons montrer la voie. Ce n’est pas parce que vous pouvez collecter des
                    données que vous devriez le faire. Nous apprécions et respectons votre vie privée et prenons
                    la protection de vos informations personnelles au sérieux.`,

  privacyPolicyP2: `Cette politique de confidentialité d’entreprise ne concerne PAS ce qui vous arrive ou vos 
                    informations personnelles lorsque vous scannez une empreinte digitale Laava. Notre politique de
                    ne pas collecter d’informations personnelles lors de la numérisation des empreintes digitales
                    Laava est couverte dans notre {{ link }} séparé`,

  privacyPolicyP3: `Cette politique de confidentialité d’entreprise définit comment Laava ID Pty Ltd 
                    (ABN 99617 775 578) (Laava, nous, notre, nous) traite vos informations personnelles dans
                    les circonstances limitées dans lesquelles nous devons collecter des informations
                    personnelles, par exemple si vous postulez à un emploi chez nous, ou cherchez à investir
                    ou à travailler avec Laava (en tant que représentant d’un partenaire,  client, fournisseur
                    ou prestataire de services), ou si vous contactez notre service d’assistance ou soumettez
                    une demande.`,

  privacyPolicyP4: `Il décrit les informations personnelles que nous recueillons à votre sujet, quand et 
                    comment nous les utilisons. Il décrit également vos droits à la vie privée en tant
                    qu’individu et comment nous contacter si vous avez des préoccupations en matière de
                    confidentialité. Cette politique de confidentialité peut changer de temps à autre et
                    vous pouvez donc la consulter régulièrement.`,

  privacyPolicyP5: `Comme indiqué dans notre Manifeste sur la protection de la vie privée, nous ne 
                    recueillons aucune information personnelle dans le cadre de la numérisation d’une
                    empreinte digitale laava.`,

  privacyPolicyP6: `Nous ne voyons ni ne recueillons vos informations personnelles lorsque vous choisissez 
                    de partager des informations personnelles avec les propriétaires de marques qui utilisent
                    nos empreintes digitales Laava (marques). Ceci est également couvert dans notre Manifeste
                    sur la protection de la vie privée.`,

  privacyPolicyP7: `Cependant, il existe des circonstances limitées dans lesquelles nous devons recueillir 
                    vos renseignements personnels directement auprès de vous, y compris lorsque vous:`,

  privacyPolicyP8: `communiquer avec nous par téléphone, par courriel, en ligne (y compris par l’entremise 
                    de notre site Web ou de notre service d’assistance) ou en personne;`,

  privacyPolicyP9: "lorsque vous postulez pour un poste chez nous;",

  privacyPolicyP10: "lorsque vous postulez pour devenir investisseur; ou",

  privacyPolicyP11: `lorsque vous interagissez avec nous en tant que client, partenaire, fournisseur ou 
                        prestataire de services potentiel ou en cours.`,

  privacyPolicyP12: `Les renseignements personnels sont toute information ou opinion (qu’elle soit vraie ou 
                        non, et qu’elle soit enregistrée sous une forme importante ou non) vous concernant en
                        tant que personne identifiée ou raisonnablement identifiable. Les types de renseignements
                        personnels que nous recueillons et traitons peuvent inclure:`,

  privacyPolicyP13: `vos coordonnées, telles que votre nom, adresse, numéro de téléphone, adresse électronique 
                        et/ou adresse IP;`,

  privacyPolicyP14: `des informations sur votre rôle au sein de votre organisation, si vous êtes un partenaire, 
                        client, fournisseur ou prestataire de services existant ou potentiel;`,

  privacyPolicyP15: `les détails financiers et autres détails pertinents que vous partagez avec nous si vous 
                        postulez pour devenir un investisseur;`,

  privacyPolicyP16: `si vous postulez à un poste chez nous, nous pouvons collecter des informations sur vos 
                        qualifications, votre expérience de compétences, votre caractère, vos vérifications de
                        présélection et vos antécédents professionnels; et`,

  privacyPolicyP17: `d’autres informations que vous nous fournissez via notre service d’assistance (ou dans 
                        toute requête que vous nous envoyez).`,

  privacyPolicyP18: `Nous recueillons, détenons, utilisons et divulguons des renseignements personnels 
                        principalement pour répondre à des questions, pour examiner votre candidature à un
                        poste chez nous ou pour vous considérer comme un investisseur, un client, un
                        fournisseur ou un partenaire de notre part. Nous pouvons également recueillir vos
                        renseignements personnels pour des raisons connexes, notamment:`,

  privacyPolicyP19: "à des fins administratives, y compris la tenue de dossiers et la production de rapports internes;",

  privacyPolicyP20: "pour communiquer avec vous;",

  privacyPolicyP21: "à des fins de marketing; et",

  privacyPolicyP22: "pour se conformer aux lois et règlements applicables.",

  privacyPolicyP23: `Dans les circonstances limitées énumérées à la section 2 ci-dessus où nous avons 
                        recueilli vos renseignements personnels, nous pouvons divulguer vos renseignements
                        personnels à des tiers. Les types de tiers avec lesquels nous échangeons des
                        informations personnelles comprennent:`,

  privacyPolicyP24: `nos fournisseurs de services, y compris la comptabilité, l’audit, le juridique, 
                        la banque, le paiement, la livraison, l’hébergement de données, la recherche,
                        le recrutement, l’assurance, la pension de retraite, la paie, la formation et
                        les services technologiques;`,

  privacyPolicyP25: `pour les personnes qui postulent pour travailler avec nous, les arbitres et les 
                        fournisseurs de vérification de dépistage (par exemple, pour les antécédents,
                        l’éligibilité à travailler, l’adéquation et les vérifications de casier judiciaire); et`,

  privacyPolicyP26: `aux organismes d’application de la loi et à d’autres organisations lorsque la 
                        loi l’exige ou le permet.`,

  privacyPolicyP27: `Nous ne divulguerons pas vos informations personnelles à des destinataires 
                        étrangers. Cependant, nous pouvons stocker vos informations personnelles
                        auprès de fournisseurs d’hébergement de données sécurisés tels que Google,
                        qui peuvent stocker ces informations aux emplacements suivants: ({{link}}).
                        Dans la mesure du possible, nous exigerons que vos informations personnelles
                        restent stockées en Australie. Si nous devons divulguer vos informations
                        personnelles à des destinataires étrangers, nous mettrons à jour cette
                        politique de confidentialité en spécifiant les pays dans lesquels ils se
                        trouvent.`,

  privacyPolicyP28: `Si vous ne nous fournissez pas les renseignements personnels au besoin, nous 
                        pourrions ne pas être en mesure de:`,

  privacyPolicyP29: "examiner votre candidature pour nous rejoindre en tant qu’employé;",

  privacyPolicyP30: `examiner votre demande pour devenir un investisseur, un client, un partenaire; 
                        fournisseur ou prestataire de services; et`,

  privacyPolicyP31: "répondre à vos questions ou demandes d’information.",

  privacyPolicyP32: `Un cookie de navigateur est un petit morceau de données qui est stocké sur 
                        votre appareil pour aider les sites Web et les applications mobiles à se
                        souvenir de choses vous concernant. Bien que nous n’utilisions pas de
                        cookies dans l’application mobile Laava, nous utilisons des cookies sur
                        notre site Web. Les cookies nous aident à voir quelles parties de notre
                        site Web sont les plus populaires, ce qui nous permet de nous concentrer
                        sur l’amélioration des aspects du site Web qui amélioreront votre expérience
                        et de vous fournir une expérience meilleure, plus intuitive et satisfaisante.
                        Votre navigateur ou votre appareil peut vous permettre de bloquer ou de
                        limiter l’utilisation des cookies. Vous pouvez également être en mesure de
                        supprimer les cookies de votre navigateur.`,

  privacyPolicyP33: `Pour aider à améliorer le service que nous fournissons via notre site Web, 
                        certaines informations peuvent être collectées à partir de votre navigateur.
                        Ces informations sont collectées sous forme d’informations statistiques et
                        comprennent l’adresse IP, le type de navigateur, la langue et les temps
                        d’accès. Dans de nombreux cas, les outils utilisés sur notre site Web
                        enregistrent des informations d’une manière qui ne vous identifie pas en
                        tant qu’individu. Dans d’autres cas, les informations peuvent être des
                        informations personnelles, auquel cas cette politique de confidentialité
                        s’appliquera.`,

  privacyPolicyP34: `Notre site Web peut également utiliser des outils de site Web tiers de temps 
                        à autre, par exemple Google Analytics et des outils d’enquête en ligne. Pour
                        en savoir plus sur la façon dont Google collecte et traite les données,
                        veuillez consulter la politique de confidentialité de Google et ses
                        informations à l’adresse suivante: ({{ link }}).`,

  privacyPolicyP35: `Nous prenons toutes les mesures raisonnables pour protéger les informations 
                        personnelles contre la perte, l’interférence, l’utilisation abusive ou
                        l’accès, la modification ou la divulgation non autorisés. Ces étapes
                        peuvent inclure des contrôles d’accès, le chiffrement et des locaux
                        sécurisés. Nous stockons des informations personnelles sous forme
                        physique et électronique, parfois avec l’aide de tiers tels que
                        des fournisseurs d’hébergement de données.`,

  privacyPolicyP36: `De temps à autre, si vous avez choisi de recevoir des informations marketing 
                        de notre part, nous pouvons vous envoyer des communications de marketing
                        direct concernant nos services.`,

  privacyPolicyP37: `Nous pouvons vous contacter par courrier électronique (par exemple, e-mail), 
                        en ligne (par exemple via notre site Web), par courrier et par d’autres
                        moyens, sauf si vous vous désabonnez ultérieurement ou si nous sommes
                        soumis à des restrictions légales. Vous pouvez vous désinscrire des
                        communications marketing en nous contactant aux coordonnées ci-dessous.`,

  privacyPolicyP38: `L’exactitude des renseignements personnels que nous détenons et utilisons 
                        est importante pour nous. Nous prenons des mesures raisonnables pour nous
                        assurer que les renseignements personnels que nous traitons sont exacts,
                        complets et à jour. Pour nous aider à garder vos informations personnelles
                        exactes, veuillez nous faire savoir s’il y a des erreurs ou des changements
                        dans vos informations personnelles.`,

  privacyPolicyP39: `Vous pouvez demander l’accès et la correction des informations personnelles 
                        que nous détenons à votre sujet à tout moment en contactant notre responsable
                        de la protection de la vie privée via les coordonnées au bas de la présente
                        politique de confidentialité. Dans la plupart des cas, nous pouvons vous
                        aider rapidement et de manière informelle avec ces demandes. Dans d’autres
                        cas, nous pouvons avoir besoin de vérifier votre identité et de vous demander
                        de faire votre demande par écrit.`,

  privacyPolicyP40: `Vous pouvez contacter notre responsable de la protection de la vie privée en 
                        utilisant les coordonnées figurant au bas de la présente politique de
                        confidentialité si vous avez des préoccupations concernant la façon dont
                        nous avons traité vos informations personnelles. Nous vous répondrons pour
                        vous faire savoir qui traitera votre affaire et quand vous pouvez vous
                        attendre à une réponse supplémentaire. Nous pouvons vous demander des
                        détails supplémentaires concernant votre préoccupation, et nous pouvons
                        avoir besoin de dialoguer ou de consulter d’autres parties pour enquêter
                        et traiter votre problème. Nous conserverons des enregistrements de votre
                        demande et de toute résolution. Si vous n’êtes pas satisfait de la manière
                        dont nous avons traité votre plainte, vous pouvez contacter le Bureau du
                        Commissaire australien à l’information.`,

  privacyPolicyP41: `Si vous avez des questions, des plaintes ou des réclamations concernant le 
                        scanner de site Web ou les présentes conditions générales, vous pouvez
                        contacter Laava à l’adresse suivante:`,
  problemsTitle: "Problèmes avec l’empreinte digitale",
  problemsIntro: `Si l’Laava Smart Fingerprint™semble endommagée ou si vous rencontrez des difficultés 
                    de numérisation, vous pouvez nous contacter et nous ferons de notre mieux pour vous
                    aider à résoudre le problème.`,
  tryScanningAgain: "Réessayez de numériser",
  emailUs: "Envoyez-nous un courriel",
  troubleDetectingFingerprint: "Nous avons de la difficulté à détecter une empreinte digitale",
  makeSureFingerprint: "Assurez-vous que l’empreinte digitale est bien éclairée et mise au point",
  ensureInternet: "Assurez-vous que votre connexion Internet est bonne",
  tryAgain: "Réessayez",
  useLegacyScanner: "Utiliser l’ancien scanner",
  logIn: "Connexion",
  account: "Compte",
  emailAddress: "Adresse e-mail",
  password: "Mot de passe",
  submit: "Envoyer",
  thereWasAProblemLoggingIn: "Il y a eu un problème lors de la connexion. Veuillez réessayer.",
  loggedInAs: "Connecté en tant que",
  logOut: "Déconnexion"
}

export default fr
