const vi = {
  pageTitle: "Máy quét Laava",
  pageDescription: "Quét vân tay thông minh Laava của bạn™ đảm bảo sản phẩm của bạn là chính hãng.",
  loading: "Tải",
  startingScanner: "Khởi động máy quét",
  privacyPolicy: "Bằng cách sử dụng Laava, bạn đồng ý với",
  cookiesPolicy: "Chính sách cookie",
  privacyAndCookies: "Quyền riêng tư & Cookie",
  termsOfUse: "Điều khoản sử dụng",
  accept: "Chấp nhận",
  copyrightContent:
    "LAAVA, DẤU VÂN TAY THÔNG MINH, DẤU VÂN TAY, XÁC THỰC MỌI THỨ, kiểm tra Laava, logo Laava và laava ???? là thương hiệu của Laava ID Pty Ltd.",
  titleWarningAllowCamera: "Vui lòng cho phép truy cập máy ảnh.",
  bodyWarningAllowCamera:
    "Máy quét web này sử dụng máy ảnh để quét Dấu vân tay. Cho phép quyền truy cập vào máy ảnh để thử lại.",
  titleWarningSupportedBrowser: "Vui lòng mở {{browser}} để quét Dấu vân tay của bạn.",
  bodyWarningSupportedBrowser: "Trình duyệt bạn đang sử dụng hiện không được hỗ trợ.",
  activateTheCameraOn: "Kích hoạt camera trên {{device}}.",
  thisWebscannerUsesTheCamera: "Máy quét web này sử dụng máy ảnh để quét Dấu vân tay.",
  scanNow: "Quét ngay",
  refresh: "Refresh",
  refreshThePage: "Làm mới trang",
  loadAgain: "Tải scan.laava.id lần nữa.",
  selectAllowWhenAsked: "Chọn cho phép khi được yêu cầu truy cập máy ảnh",
  whenAskedSelectAllow: "Khi được hỏi scan.laava.id muốn truy cập camera chọn cho phép.",
  positionTheCode: "Định vị mã trong khung để quét",
  moveCloserToQR: "Di chuyển gần mã QR hơn",
  moveFurtherFromQR: "Di chuyển xa mã QR hơn",
  centerQRCode: "Đặt mã QR vào giữa",
  unblurQrCode: "Mã QR bị mờ",
  goodQrCode: "Mã QR hợp lệ",
  badQrCode: "Không phát hiện mã QR",
  qrCodeError: "Lỗi mã QR",
  seeSupportedBrowsers: "Khởi chạy trình duyệt",
  supportedBrowsersPageTitle: "Máy quét này yêu cầu trình duyệt có hỗ trợ camera.",
  supportedBrowsersPageBody: "Hệ điều hành thiết bị của bạn sẽ xác định trình duyệt web nào có hỗ trợ camera.",
  howUnblockCamera: "Cách bỏ chặn máy ảnh",
  help: "Trợ giúp",
  supportedBrowsersPageHelpBody: "Nếu bạn có thắc mắc hoặc thắc mắc, vui lòng liên hệ với chúng tôi.",
  contactUs: "Liên hệ với chúng tôi",
  goToBrowserSettings: "Đi tới cài đặt trình duyệt",
  findSettingBrowser:
    "Bạn có thể tìm thấy cài đặt bằng cách nhấn vào ba dấu chấm ở góc trên cùng bên phải của trình duyệt.",
  selectSiteSettings: "Chọn thiết đặt trang",
  foundAdvanced: "Điều này có thể được tìm thấy dưới tiêu đề phụ nâng cao.",
  selectCamera: "Chọn máy ảnh",
  selectWebsite: "Chọn 'https://scan.laava.id'",
  changePermission: "Thay đổi quyền truy cập vào máy ảnh của bạn, để cho phép.",
  returnToWebscanner: "Quay lại máy quét web để quét.",
  lookForLaava: "Tìm Laava.",
  simplyScan: "Chỉ cần quét.",
  shopWithAssurance: "Mua sắm với Assurance.",
  laavaEnablesBrands:
    "Laava cho phép các thương hiệu cung cấp cho bạn sự đảm bảo về chất lượng sản phẩm của họ bằng cách sử dụng công nghệ mới, an toàn được phát triển với CSIRO.",
  aboutLaava: "Về Laava",
  knowYouReGetting: "Biết bạn đang nhận được điều thực sự và không phải là người thay thế",
  laavaIsANewKind:
    "Laava là một loại mã vạch mới có thể giúp đảm bảo sản phẩm bạn đang trả tiền là thực tế chính hãng và giữ cho bạn an toàn khỏi hàng giả chất lượng thấp hơn.",
  everyFingerprint: "Mỗi dấu vân tay là hoàn toàn độc đáo và không giống bất kỳ dấu vân tay nào khác",
  aFingerprintDoes:
    "Dấu vân tay không chỉ cho biết bạn đang nắm giữ sản phẩm hoặc lô nào. Nó là một dấu vân tay cá nhân, hoàn toàn độc đáo cho sản phẩm chính xác trong tay bạn.",
  seeBeyondTheLabel: "Xem bên ngoài nhãn và biết thêm về sản phẩm của bạn",
  scanningAllowsYou:
    "Quét cho phép bạn xem nhiều thông tin hơn là chỉ những gì có trên nhãn. Tìm hiểu thông tin cụ thể về mặt hàng chính xác đó, chẳng hạn như nơi sản xuất hoặc khi nó được đóng gói.",
  ourCompany: "Công ty chúng tôi",
  laavaWasFounded:
    "Laava được thành lập tại Úc vào năm 2016. Khái niệm và giải pháp công nghệ đã được phát triển bởi các chuyên gia hàng đầu thế giới trong lĩnh vực quang học, blockchain, tiếp thị, hiểu biết của người tiêu dùng.",
  needHelpScanning: "Bạn cần trợ giúp quét?",
  youCanFindDetails:
    "Bạn có thể tìm thấy chi tiết về quét, cùng với câu trả lời cho các vấn đề phổ biến, trong hướng dẫn trợ giúp của chúng tôi.",
  getHelp: "Nhận trợ giúp về quét",
  howScan: "Cách quét",
  problemsWithTheFingerprint: "Sự cố với dấu vân tay",
  commonProblems: "Các vấn đề thường gặp",
  getResults:
    "Để có được kết quả tốt nhất, những mẹo này có thể giúp bạn khắc phục một số vấn đề phổ biến có thể ảnh hưởng đến chất lượng quét của bạn.",
  readyToScan: "Bạn đã sẵn sàng quét dấu vân tay chưa?",
  needMoreHelp: "Bạn cần thêm trợ giúp?",
  scanFingerprint: "Quét dấu vân tay của bạn",
  bringFingerprint:
    "Lấy nét Vân tay trong chế độ xem camera và giữ tại chỗ cho đến khi phát hiện vân tay. Quá trình này có thể mất vài phút tùy thuộc vào tốc độ kết nối của bạn.",
  locateSmartFingerprint: "Định vị dấu vân tay thông minh của bạn™",
  fingerprintsCanBeFound: "Dấu vân tay có thể được tìm thấy trên nhiều sản phẩm hàng ngày.",
  alignTheFingerprint: "Căn chỉnh Vân tay bằng hướng dẫn",
  alignTheGuide: "Căn chỉnh hướng dẫn đến các vòng bên trong và bên ngoài của Dấu vân tay.",
  snapPhoto: "Đính ảnh",
  getYourFingerprint: "Lấy nét vân tay của bạn và giữ cho bàn tay của bạn ổn định.",
  light: "Ánh sáng",
  likeAnyCamera:
    "Giống như bất kỳ máy ảnh nào, kết quả tốt nhất là trong ánh sáng tốt. Đảm bảo dấu vân tay của bạn được chiếu sáng tốt và quan sát ánh sáng chói.",
  keepYourHands: "Giữ cho đôi tay của bạn ổn định",
  tryKeepYourHand: "Cố gắng giữ cho tay và máy ảnh của bạn đứng yên khi chụp ảnh, để ảnh rõ.",
  considerTheAngle: "Xem xét góc",
  scanYourFingerprint:
    "Quét dấu vân tay của bạn ở vị trí phía trước máy ảnh và lấp đầy hầu hết chế độ xem (trong khi vẫn giữ nét).",
  watchOutForShadow: "Coi chừng bóng tối",
  whenScanning: "Khi quét, hãy đảm bảo điện thoại của bạn không đổ bóng tối lên Dấu vân tay.",
  haveAnyQuestion: "Bạn có bất kỳ câu hỏi hoặc phản hồi nào? Chúng tôi rất muốn nghe từ bạn.",
  email: "Email",
  startingCamera: "Khởi động máy ảnh",
  searching: "Quét",
  analysing: "Phân tích",
  sending: "Gửi",
  sendingForVerification: "Gửi để xác minh",
  mightTakeAFewMoments: "Có thể mất vài phút",
  placeCodeWithinFrame: "Đặt mã trong khung để quét",
  needHelp: "Cần giúp đỡ?",
  googlePrivacyPolicy: "Chính sách quyền riêng tư của Google",
  laavaPrivacy: "Quyền riêng tư của Laava",
  laavaHasAPrivacy:
    "Laava có một bản tuyên ngôn {{ manifesto }} giải thích các chính sách của chúng tôi ảnh hưởng đến bạn và quyền riêng tư của bạn với tư cách là người dùng Dấu vân tay Laava. Một cách riêng biệt, chúng tôi cũng có chính sách {{ policy }} chỉ áp dụng trong những trường hợp hạn chế mà chúng tôi phải thu thập thông tin cá nhân.",
  privacyManifesto: "Tuyên ngôn về Quyền riêng tư",
  corporatePrivacy: "Chính sách bảo mật của công ty",
  laavaWebscannerTerm: "Laava - Điều khoản và điều kiện của Webscanner",
  general: "Tổng quát",
  websiteUse: "Sử dụng trang web",
  intellectualProperty: "Sở hữu trí tuệ",
  limitionOfLiability: "Giới hạn trách nhiệm pháp lý",
  indemnity: "Bồi thường",
  otherWebsites: "Các trang web và bên thứ ba khác",
  entireAgreement: "Toàn bộ thỏa thuận",
  invalidity: "Vô hiệu và khả năng thực thi",
  privacy: "Bảo mật",
  applicableLaw: "Luật áp dụng",
  howToContact: "Cách liên hệ với chúng tôi",
  termsP00:
    "Cảm ơn bạn đã truy cập Trang web Laava (Trang web). Trang web được sở hữu và điều hành bởi Laava ID Pty Ltd (A.B.N. 99 617 775 578) (Laava).",
  termsP01:
    "Việc bạn sử dụng Trang web chịu sự điều chỉnh của các Điều khoản Sử dụng này. Bằng cách truy cập và sử dụng Trang web mà bạn đồng ý bằng cách bị ràng buộc bởi các Điều khoản sử dụng này.",
  termsP02:
    "Laava có thể thay đổi một phần hoặc tất cả các Điều khoản Sử dụng này bất cứ lúc nào. Điều khoản sử dụng mới nhất sẽ luôn được đăng trên Trang web. Việc bạn tiếp tục sử dụng Trang web sau đó hoặc tiếp tục sẽ cấu thành việc bạn chấp nhận bất kỳ thay đổi nào được thực hiện đối với các Điều khoản sử dụng này. Nếu bạn phản đối các Điều khoản sử dụng này hoặc bất kỳ thay đổi nào được thực hiện đối với chúng, bạn nên ngừng sử dụng Trang web.",
  termsP03: "Các điều khoản này được cập nhật lần cuối vào ngày 26 tháng 11 năm 2018.",
  termsP1: "Bạn chỉ có thể sử dụng Trang web hoặc bất kỳ Nội dung nào cho các mục đích phi thương mại.",
  termsP2: "Thông tin trên Trang web chỉ dành cho mục đích thông tin chung.",
  termsP3:
    "Trang web và thông tin, liên kết, tài liệu hoặc đồ họa liên quan có trên Trang web (cùng nhau, Nội dung) được cung cấp 'như hiện tại'. Bất kỳ sự phụ thuộc nào bạn đặt vào Nội dung đều hoàn toàn có nguy cơ của riêng bạn. Bạn cần thực hiện các yêu cầu của riêng mình để xác định xem Nội dung có phù hợp với mục đích sử dụng của bạn hay không.",
  termsP4:
    "Mặc dù Laava nỗ lực hết sức hợp lý để đảm bảo rằng Trang web luôn sẵn sàng và hoạt động đầy đủ, quyền truy cập của bạn vào Trang web có thể bị đình chỉ mà không cần thông báo trong trường hợp hệ thống bị lỗi, bảo trì hoặc bất kỳ lý do nào ngoài tầm kiểm soát của Laava.",
  termsP5: "Laava có thể thay đổi hoặc ngừng hoạt động, tạm thời hoặc vĩnh viễn, như sau:",
  termsP6: "Trang web, hoặc bất kỳ Nội dung, tính năng hoặc dịch vụ nào trên Trang web; Hoặc",
  termsP7: "đường dẫn hoặc vị trí của nối kết,",
  termsP8:
    "bất cứ lúc nào mà không cần thông báo trước. Laava từ chối mọi trách nhiệm pháp lý liên quan đến những thay đổi, thay đổi, ngừng hoặc gián đoạn Trang web hoặc bất kỳ phần nào của Trang web.",
  termsP9:
    "Laava có quyền chấm dứt quyền truy cập của bạn vào Trang web và ngăn chặn truy cập thêm mà không cần cảnh báo hoặc thông báo để tự mình vi phạm các Điều khoản sử dụng này, vi phạm bất kỳ luật hiện hành hoặc hoạt động đáng ngờ nào.",
  termsP10: "Khi sử dụng Trang web, bạn đồng ý không:",
  termsP11: "cung cấp bất kỳ thông tin sai lệch, gây hiểu lầm hoặc lừa đảo nào",
  termsP12: "sử dụng Trang web theo cách vi phạm hoặc có thể vi phạm bất kỳ luật hoặc quy định nào",
  termsP13: "sử dụng Trang web theo cách có thể khiến Laava vi phạm bất kỳ luật hoặc quy định nào",
  termsP14: "sử dụng Trang web theo cách phản ánh không thuận lợi trên Laava hoặc bất kỳ bên thứ ba nào khác",
  termsP15:
    "thu thập thông tin về những người dùng khác của Trang web cho bất kỳ mục đích nào khác ngoài việc được chúng tôi ủy quyền rõ ràng",
  termsP16:
    "thực hiện bất kỳ hành động nào, theo ý kiến của Laava, áp đặt một gánh nặng vô lý lên cơ sở hạ tầng của Trang web;",
  termsP17:
    "phân phối vi-rút máy tính hoặc bất kỳ chương trình hoặc mã tương tự nào có thể làm gián đoạn hoặc vô hiệu hóa Trang web",
  termsP18:
    "sử dụng thiết bị, bất kỳ phần mềm hoặc quy trình nào hoặc các phương tiện tự động khác để truy xuất, cạo, sao chép hoặc lập chỉ mục Trang web hoặc bất kỳ Nội dung nào cho bất kỳ mục đích nào; Hoặc",
  termsP19:
    "truy xuất, cạo, sao chép hoặc lập chỉ mục Trang web hoặc bất kỳ Nội dung nào với mục đích tạo cơ sở dữ liệu thông tin bất động sản.",
  termsP20:
    "Trang web và tất cả các tài sản trí tuệ trong Nội dung trên Trang web, bao gồm (nhưng không giới hạn) bất kỳ văn bản, đồ họa, hình ảnh, logo hoặc nhãn hiệu thương mại nào đều thuộc sở hữu hoặc được cấp phép cho Laava, trừ khi có quy định khác.",
  termsP21:
    "Trừ khi cần thiết để xem Nội dung trên Trang web, khi được pháp luật cho phép hoặc khi bạn đã có được sự cho phép rõ ràng bằng văn bản của Laava, bạn không được sao chép, biên dịch lại, dịch ngược, tháo rời, thiết kế ngược, tạo ra các tác phẩm phái sinh từ, thực hiện, sửa đổi, điều chỉnh, xuất bản, lưu trữ, sử dụng, truyền tải điện tử hoặc cách khác, phân phối hoặc theo bất kỳ cách nào khác khai thác, bằng bất kỳ phương tiện nào khác toàn bộ hoặc một phần Trang web hoặc Nội dung trên Trang web cho các mục đích khác ngoài mục đích sử dụng cá nhân, phi thương mại của riêng bạn.",
  termsP22: "Việc bạn sử dụng Trang web được thực hiện với rủi ro của riêng bạn.",
  termsP23:
    "Trong phạm vi tối đa được pháp luật cho phép, Laava từ chối tất cả các tuyên bố, bảo đảm hoặc bảo đảm, rõ ràng hay ngụ ý, bao gồm, nhưng không giới hạn ở:",
  termsP24: "tính đầy đủ, chính xác, độ tin cậy, sự phù hợp hoặc tính khả dụng đối với Trang web hoặc Nội dung",
  termsP25: "rằng Trang web và Nội dung sẽ không có lỗi hoặc các khiếm khuyết sẽ được sửa chữa",
  termsP26: "rằng quyền truy cập của bạn vào Trang web sẽ không bị gián đoạn, an toàn hoặc không có lỗi",
  termsP27: "rằng Trang web không có vi-rút hoặc bất kỳ thành phần có hại nào khác; Và",
  termsP28:
    "chất lượng, độ chính xác hoặc sự phù hợp cho mục đích của bất kỳ trang web được liên kết nào hoặc tài liệu của bên thứ ba.",
  termsP29:
    "Trong phạm vi tối đa được pháp luật cho phép, Laava và các giám đốc, nhân viên và đại lý của Laava, không chịu trách nhiệm với bạn về bất kỳ tổn thất hoặc thiệt hại nào (bao gồm cả tổn thất do hậu quả) gây ra như thế nào, cho dù trong hợp đồng, sai phạm bao gồm sơ suất, đạo luật hoặc phát sinh liên quan đến Trang web hoặc Nội dung.",
  termsP30:
    "Không có nội dung nào trong các Điều khoản Sử dụng này nhằm loại trừ, hạn chế hoặc sửa đổi các quyền mà bạn có thể có theo bất kỳ luật nào (bao gồm cả ở Úc, Luật Người tiêu dùng Úc) có thể không bị loại trừ, hạn chế hoặc sửa đổi theo thỏa thuận. Nếu các Điều khoản Sử dụng này được điều chỉnh bởi Luật Người tiêu dùng Úc, trách nhiệm của chúng tôi đối với bạn đối với việc không tuân thủ bảo lãnh của người tiêu dùng đối với bất kỳ dịch vụ nào được giới hạn ở việc cung cấp lại các dịch vụ hoặc thanh toán cho việc cung cấp lại đó.",
  termsP31:
    "Bạn bồi thường cho Laava về bất kỳ trách nhiệm pháp lý nào mà Laava phải chịu đối với bất kỳ tổn thất, trách nhiệm pháp lý hoặc thiệt hại, chi phí nào, bao gồm nhưng không giới hạn, phí pháp lý và kế toán hợp lý, bất kể nguyên nhân gây ra, do Laava phải chịu do bạn vi phạm các Điều khoản Sử dụng này hoặc việc bạn sử dụng Trang web.",
  termsP32:
    "Trang web có thể chứa các liên kết đến các trang web, quảng cáo hoặc thông tin của bên thứ ba trên các trang web đó.",
  termsP33:
    "Laava không kiểm soát bản chất, nội dung và tính khả dụng của các trang web của bên thứ ba. Việc bao gồm bất kỳ liên kết nào đến các trang web khác không nhất thiết ngụ ý tài trợ, chứng thực hoặc phê duyệt các trang web đó hoặc các quan điểm được thể hiện trong đó.",
  termsP34:
    "Laava không chịu trách nhiệm về nội dung trên các trang web đó. Bất kỳ việc sử dụng các trang web và thông tin của bên thứ ba đều có nguy cơ của riêng bạn.",
  termsP35:
    "Các Điều khoản Sử dụng này thể hiện toàn bộ thỏa thuận giữa bạn và Laava liên quan đến việc bạn sử dụng và truy cập vào Trang web cũng như việc bạn sử dụng và truy cập vào Nội dung trên đó. Không có thuật ngữ nào khác được đưa vào các Điều khoản Sử dụng này trừ khi nó được yêu cầu phải được bao gồm bởi bất kỳ luật nào của Khối thịnh vượng chung Úc hoặc bất kỳ Tiểu bang hoặc Lãnh thổ nào của Úc. Tất cả các điều khoản ngụ ý ngoại trừ những điều khoản được ngụ ý theo đạo luật và không thể loại trừ rõ ràng được loại trừ rõ ràng.",
  termsP36:
    "Nếu bất kỳ điều khoản nào trong các Điều khoản sử dụng này không hợp lệ theo luật của bất kỳ khu vực tài phán nào, thì điều khoản này có thể được thực thi trong khu vực tài phán đó trong phạm vi không hợp lệ, cho dù nó có thuộc các điều khoản nghiêm trọng hay không.",
  termsP37:
    "Tất cả thông tin cá nhân mà Laava thu thập từ bạn sẽ được xử lý theo Đạo luật Quyền riêng tư 1988 (Cth) và {{policy}}",
  termsP38:
    "Để biết thêm thông tin về cách Laava xử lý thông tin cá nhân của bạn khi bạn quét Dấu vân tay Laava, tham khảo {{manifesto}}",
  termsP39:
    "Laava có quyền bất cứ lúc nào và theo thời gian để sửa đổi, ngừng tạm thời hoặc vĩnh viễn Máy quét trang web, hoặc bất kỳ phần nào trong đó, vì bất kỳ lý do gì. Laava từ chối mọi trách nhiệm pháp lý do bất kỳ sự gián đoạn hoặc gián đoạn nào của Máy quét trang web hoặc bất kỳ phần nào của nó",
  termsP40: "Bạn có thể liên hệ với Laava qua:",
  contact: "Liên hệ",
  privacyOfficer: "Nhân viên Bảo mật",
  address: "Địa chỉ",
  phone: "Điện thoại",
  laavaPrivacyManifesto: "Tuyên ngôn về quyền riêng tư của Laava",
  privacyManifestoIntroduction: "Laava - Tuyên ngôn bảo mật",
  ourApproach: "Cách tiếp cận của chúng tôi đối với quyền riêng tư của bạn",
  whatInformationScan: "Thông tin nào được thu thập khi tôi quét dấu vân tay Laava?",
  whatInformationDetect: "Thông tin nào được thu thập khi chúng tôi phát hiện hoạt động đáng ngờ?",
  howDoesLaava: "Laava sử dụng cookie như thế nào?",
  sharingInformation: "Chia sẻ Thông tin",
  privacyManifestoP1:
    "Tài liệu này là Tuyên ngôn quyền riêng tư của chúng tôi phác thảo chính sách của chúng tôi liên quan đến việc thu thập thông tin khi bạn quét Dấu vân tay thông minh Laava bằng trình duyệt hoặc máy quét WeChat Laava.",
  privacyManifestoP2:
    "Chúng tôi có một {{ link }} riêng biệt quy định cách chúng tôi xử lý thông tin cá nhân của bạn trong các trường hợp kinh doanh khác, chẳng hạn như nếu bạn nộp đơn xin một vị trí với chúng tôi, nếu bạn là nhà đầu tư tiềm năng, đại diện của đối tác, khách hàng, nhà cung cấp hoặc nhà cung cấp dịch vụ của chúng tôi hoặc nếu bạn liên hệ với bộ phận trợ giúp của chúng tôi hoặc gửi câu hỏi hoặc truy vấn với chúng tôi.",
  privacyManifestoP3:
    "Chúng tôi không muốn thu thập bất kỳ thông tin cá nhân nào của bạn khi bạn quét Dấu vân tay thông minh Laava. Tôn trọng quyền riêng tư của bạn là mối quan tâm chính của chúng tôi, đó là lý do tại sao chúng tôi không yêu cầu tên, đăng nhập, số điện thoại, email hoặc bất kỳ thông tin nào khác nhận dạng cá nhân bạn.",
  privacyManifestoP4:
    "Quét dấu vân tay thông minh Laava là cốt lõi của mọi thứ chúng tôi làm. Chúng tôi muốn cung cấp một môi trường an toàn, riêng tư và không lo lắng cho tất cả những ai quét Dấu vân tay thông minh Laava. Quét dấu vân tay thông minh Laava có nghĩa là không có rắc rối và không có con mắt tò mò.",
  privacyManifestoP5:
    "Chúng tôi nghĩ rằng rất nhiều công ty có thể làm tốt hơn liên quan đến quyền riêng tư của người tiêu dùng và chúng tôi muốn dẫn đầu. Chỉ vì bạn có thể thu thập dữ liệu, không có nghĩa là bạn nên.",
  privacyManifestoP6:
    "Khi bạn quét Dấu vân tay thông minh Laava, chúng tôi sẽ chụp ảnh bản quét của bạn và các thông tin quét khác, hoặc siêu dữ liệu, cần thiết để xác thực Dấu vân tay thông minh Laava và cập nhật bản ghi trạng thái của nó. Siêu dữ liệu đó có thể bao gồm URL mà từ đó quá trình quét bắt nguồn, vị trí quét (thường bắt nguồn từ địa chỉ IP hoặc vị trí GPS) và thời gian của mỗi lần quét. Chúng tôi cũng chỉ định và ghi lại ID máy quét ẩn danh với mỗi lần quét và có thể sử dụng thông tin thiết bị hạn chế như cài đặt trình duyệt và máy ảnh.",
  privacyManifestoP7:
    "Quan trọng hơn, tất cả thông tin chúng tôi thu thập khi bạn quét Dấu vân tay thông minh Laava được ẩn danh và không thể dễ dàng sử dụng để nhận dạng bạn (Thông tin ẩn danh ). Nếu hình ảnh quét của bạn bao gồm thông tin có thể được sử dụng để nhận dạng bạn, chúng tôi sẽ không tìm cách làm như vậy.",
  privacyManifestoP8:
    "Nếu quý vị quét Dấu Vân Tay Thông Minh Laava trong chương trình nhỏ WeChat của chúng tôi, quý vị cũng sẽ phải tuân theo chính sách bảo mật của WeChat.",
  privacyManifestoP9:
    "Để đảm bảo Laava là một công cụ hiệu quả chống hàng giả và gian lận, và để giữ cho môi trường quét của bạn an toàn và duy trì chất lượng dịch vụ, hiệu suất và bảo mật cao, chúng tôi sử dụng một loạt các phương pháp để xác định xem hoạt động quét của một Dấu vân tay thông minh Laava cá nhân có đáng ngờ hay không. Khi phát hiện hoạt động đáng ngờ, chúng tôi có thể tiến hành phân tích kỹ lưỡng hoạt động quét và siêu dữ liệu, cũng như chuyển thông tin này cho khách hàng và đối tác của chúng tôi (cùng với Khách hàng).",
  privacyManifestoP10:
    "Laava sử dụng cookie trình duyệt trên trang web của mình. Cookie trình duyệt là một phần nhỏ dữ liệu được lưu trữ trên thiết bị của bạn để giúp các trang web ghi nhớ những điều về trình duyệt của bạn. Cookie giúp chúng tôi xem phần nào của trang web của chúng tôi là phổ biến nhất, cho phép chúng tôi cải thiện trải nghiệm của bạn.",
  privacyManifestoP11:
    "Để giúp cải thiện trang web của chúng tôi, một số thông tin nhất định có thể được thu thập dưới dạng thông tin thống kê và có thể bao gồm địa chỉ IP, loại trình duyệt, ngôn ngữ và thời gian truy cập. Các công cụ được sử dụng trên thông tin hồ sơ trang web của chúng tôi theo cách không xác định bạn là một cá nhân. Trình duyệt hoặc thiết bị của bạn có thể cho phép bạn chặn, hạn chế sử dụng hoặc xóa cookie.",
  privacyManifestoP12:
    "Khi bạn quét Dấu vân tay thông minh Laava bằng máy quét dựa trên trình duyệt, cookie có thể được sử dụng, nhưng lịch sử quét của bạn sẽ không được lưu trữ.",
  privacyManifestoP13:
    "Trang web của chúng tôi cũng có thể sử dụng các công cụ trang web của bên thứ ba theo thời gian, ví dụ: Google Analytics và các công cụ khảo sát trực tuyến. Để biết thêm về cách Google thu thập và xử lý dữ liệu, vui lòng xem chính sách quyền riêng tư của Google và thông tin của họ tại {{ link }}",
  privacyManifestoP14:
    "Khách hàng rõ ràng quan tâm đến việc phân tích hồ sơ quét Dấu vân tay thông minh Laava liên quan đến các mặt hàng của họ. Chúng tôi chia sẻ thông tin quét về Dấu vân tay thông minh Laava với Khách hàng, thường ở dạng tổng hợp. Khi chúng tôi chia sẻ thông tin với Khách hàng, chúng tôi chỉ cho họ biết những gì họ cần biết, chẳng hạn như ở đâu, khi nào và bao nhiêu lần Dấu vân tay thông minh Laava của họ được quét. Vì chúng tôi không thu thập thông tin cá nhân của bạn, chúng tôi không thể chia sẻ thông tin đó với họ.",
  privacyManifestoP15:
    "Chúng tôi cũng cho phép Khách hàng tương tác với bạn trên cơ sở cá nhân khi bạn quét Dấu vân tay thông minh Laava. Là một phần của cam kết này, Khách hàng có thể yêu cầu thông tin cá nhân từ bạn. Chúng tôi nghĩ rằng điều đó thật tuyệt, nhưng tại Laava, việc chia sẻ bất kỳ thông tin cá nhân nào luôn được thực hiện trên cơ sở minh bạch và chọn tham gia. Bạn luôn chịu trách nhiệm và có thể chọn những gì bạn chia sẻ.",
  privacyManifestoP16:
    "Laava sẽ không xem hoặc thu thập thông tin cá nhân của bạn, thay vào đó, bất kỳ thông tin cá nhân nào bạn chia sẻ sẽ được khách hàng của chúng tôi thu thập và xử lý theo chính sách bảo mật của họ, mà bạn sẽ được yêu cầu chấp nhận.",
  privacyManifestoP17:
    "Laava có thể chia sẻ bất kỳ thông tin nào bạn cung cấp khi tin rằng việc phát hành là phù hợp để tuân thủ luật pháp, để tạo điều kiện thuận lợi cho thủ tục tố tụng của tòa án hoặc bảo vệ quyền, tài sản hoặc sự an toàn của Laava, người dùng trang web này và những người khác.",
  laavaPrivacyPolicy: "Chính sách về quyền riêng tư của Laava",
  privacyPolicyT1: "Giới thiệu về Chính sách bảo mật doanh nghiệp này",
  privacyPolicyT2: "Khi nào chúng tôi thu thập thông tin cá nhân của bạn?",
  privacyPolicyT3: "Chúng tôi thu thập và xử lý thông tin cá nhân nào?",
  privacyPolicyT4: "Tại sao chúng tôi thu thập thông tin cá nhân của bạn?",
  privacyPolicyT5: "Chúng tôi có thể tiết lộ thông tin cá nhân của bạn cho ai?",
  privacyPolicyT6: "Nếu chúng tôi không thể thu thập thông tin cá nhân của bạn thì sao?",
  privacyPolicyT7: "Khách truy cập vào trang web và cookie của chúng tôi",
  privacyPolicyT8: "Làm thế nào để chúng tôi bảo vệ thông tin cá nhân của bạn?",
  privacyPolicyT9: "Truyền thông tiếp thị trực tiếp",
  privacyPolicyT10: "Làm thế nào bạn có thể truy cập và sửa thông tin cá nhân của bạn?",
  privacyPolicyT11: "Làm thế nào bạn có thể khiếu nại về quyền riêng tư?",
  privacyPolicyT12: "Cách liên hệ với chúng tôi",
  privacyPolicyP1:
    "Chúng tôi nghĩ rằng rất nhiều công ty có thể làm tốt hơn liên quan đến quyền riêng tư của người tiêu dùng và chúng tôi muốn dẫn đầu. Chỉ vì bạn có thể thu thập dữ liệu, không có nghĩa là bạn nên. Chúng tôi đánh giá cao và tôn trọng quyền riêng tư của bạn và coi trọng việc bảo vệ thông tin cá nhân của bạn.",
  privacyPolicyP2:
    "Chính sách bảo mật của công ty này KHÔNG phải là về những gì xảy ra với bạn hoặc thông tin cá nhân của bạn khi bạn quét Dấu vân tay Laava. Chính sách của chúng tôi về việc không thu thập thông tin cá nhân khi quét Dấu vân tay Laava được đề cập trong {{ link }} riêng biệt của chúng tôi",
  privacyPolicyP3:
    "Chính sách bảo mật của công ty này quy định cách Laava ID Pty Ltd (ABN 99617 775 578) (Laava, chúng tôi, của chúng tôi, chúng tôi) xử lý thông tin cá nhân của bạn trong những trường hợp hạn chế mà chúng tôi phải thu thập thông tin cá nhân, chẳng hạn như nếu bạn nộp đơn xin việc với chúng tôi hoặc tìm cách đầu tư hoặc làm việc với Laava (với tư cách là đại diện của đối tác, khách hàng , nhà cung cấp hoặc nhà cung cấp dịch vụ) hoặc nếu bạn liên hệ với bộ phận trợ giúp của chúng tôi hoặc gửi truy vấn.",
  privacyPolicyP4:
    "Nó phác thảo thông tin cá nhân mà chúng tôi thu thập về bạn, thời gian và cách chúng tôi sử dụng thông tin đó. Nó cũng phác thảo các quyền riêng tư của bạn với tư cách cá nhân và cách liên hệ với chúng tôi nếu bạn có bất kỳ lo ngại nào về quyền riêng tư. Chính sách bảo mật này có thể thay đổi theo thời gian và vì vậy bạn có thể muốn xem xét nó thường xuyên.",
  privacyPolicyP5:
    "Như được nêu trong Tuyên ngôn quyền riêng tư của chúng tôi, chúng tôi không thu thập bất kỳ thông tin cá nhân nào liên quan đến việc quét Dấu vân tay Laava.",
  privacyPolicyP6:
    "Chúng tôi cũng không xem hoặc thu thập thông tin cá nhân của bạn khi bạn chọn chia sẻ thông tin cá nhân với chủ sở hữu của các thương hiệu sử dụng Dấu vân tay Laava (Thương hiệu) của chúng tôi. Điều này cũng được đề cập trong Tuyên ngôn quyền riêng tư của chúng tôi.",
  privacyPolicyP7:
    "Tuy nhiên, có một số trường hợp hạn chế mà chúng tôi cần thu thập thông tin cá nhân của bạn trực tiếp từ bạn, bao gồm cả khi bạn:",
  privacyPolicyP8:
    "liên lạc với chúng tôi qua điện thoại, email, trực tuyến (bao gồm thông qua trang web hoặc bộ phận trợ giúp của chúng tôi), hoặc trực tiếp",
  privacyPolicyP9: "khi bạn nộp đơn xin một vị trí với chúng tôi",
  privacyPolicyP10: "khi bạn nộp đơn xin trở thành nhà đầu tư; Hoặc",
  privacyPolicyP11:
    "khi bạn tương tác với chúng tôi với tư cách là khách hàng, đối tác, nhà cung cấp hoặc nhà cung cấp dịch vụ tiềm năng hoặc liên tục.",
  privacyPolicyP12:
    "Thông tin cá nhân là bất kỳ thông tin hoặc ý kiến nào (dù đúng hay không, và có được ghi lại dưới dạng tài liệu hay không) về bạn với tư cách là một cá nhân được xác định hoặc một cá nhân có thể nhận dạng hợp lý. Các loại thông tin cá nhân chúng tôi thu thập và xử lý có thể bao gồm:",
  privacyPolicyP13: "thông tin liên lạc, chẳng hạn như tên, địa chỉ, số điện thoại, email và/hoặc địa chỉ IP của bạn",
  privacyPolicyP14:
    "thông tin về vai trò của bạn trong tổ chức của bạn, nếu bạn là đối tác hiện tại hoặc tiềm năng, khách hàng, nhà cung cấp hoặc nhà cung cấp dịch vụ của chúng tôi",
  privacyPolicyP15:
    "tài chính và các chi tiết liên quan khác mà bạn chia sẻ với chúng tôi nếu bạn nộp đơn xin trở thành nhà đầu tư",
  privacyPolicyP16:
    "nếu bạn nộp đơn xin một vị trí với chúng tôi, chúng tôi có thể thu thập thông tin về trình độ, kinh nghiệm kỹ năng, tính cách, kiểm tra sàng lọc và lịch sử việc làm của bạn; Và",
  privacyPolicyP17:
    "thông tin khác mà bạn cung cấp cho chúng tôi thông qua bộ phận trợ giúp của chúng tôi (hoặc trong bất kỳ truy vấn nào bạn gửi cho chúng tôi).",
  privacyPolicyP18:
    "Chúng tôi thu thập, lưu giữ, sử dụng và tiết lộ thông tin cá nhân chủ yếu để trả lời các câu hỏi, xem xét đơn đăng ký của bạn cho một vị trí với chúng tôi hoặc coi bạn là nhà đầu tư, khách hàng, nhà cung cấp hoặc đối tác của chúng tôi. Chúng tôi cũng có thể thu thập thông tin cá nhân của bạn vì những lý do liên quan, bao gồm:",
  privacyPolicyP19: "mục đích hành chính bao gồm lưu giữ hồ sơ và báo cáo nội bộ",
  privacyPolicyP20: "để liên lạc với bạn",
  privacyPolicyP21: "cho mục đích tiếp thị; Và",
  privacyPolicyP22: "tuân thủ luật pháp và quy định hiện hành.",
  privacyPolicyP23:
    "Trong các trường hợp hạn chế được liệt kê trong phần 2 ở trên khi chúng tôi đã thu thập thông tin cá nhân của bạn, chúng tôi có thể tiết lộ thông tin cá nhân của bạn cho bên thứ ba. Các loại bên thứ ba mà chúng tôi trao đổi thông tin cá nhân bao gồm:",
  privacyPolicyP24:
    "các nhà cung cấp dịch vụ của chúng tôi, bao gồm kế toán, kiểm toán, pháp lý, ngân hàng, thanh toán, giao hàng, lưu trữ dữ liệu, nghiên cứu, tuyển dụng, bảo hiểm, trợ cấp, bảng lương, đào tạo và dịch vụ công nghệ",
  privacyPolicyP25:
    "đối với những người nộp đơn xin làm việc với chúng tôi, trọng tài và nhà cung cấp kiểm tra sàng lọc (ví dụ: về lý lịch, tính đủ điều kiện làm việc, sự phù hợp và kiểm tra hồ sơ tội phạm); Và",
  privacyPolicyP26: "cho các cơ quan thực thi pháp luật và các tổ chức khác khi pháp luật yêu cầu hoặc cho phép.",
  privacyPolicyP27:
    "Chúng tôi sẽ không tiết lộ thông tin cá nhân của bạn cho bất kỳ người nhận ở nước ngoài nào. Tuy nhiên, chúng tôi có thể lưu trữ thông tin cá nhân của bạn với các nhà cung cấp dịch vụ lưu trữ dữ liệu an toàn như Google, những người có thể lưu trữ thông tin đó ở các vị trí sau: ({{link}}). Nếu có thể, chúng tôi sẽ yêu cầu thông tin cá nhân của bạn vẫn được lưu trữ tại Úc. Nếu chúng tôi cần tiết lộ thông tin cá nhân của bạn cho bất kỳ người nhận ở nước ngoài nào, chúng tôi sẽ cập nhật Chính sách bảo mật này bằng cách chỉ định các quốc gia nơi họ sinh sống.",
  privacyPolicyP28: "Nếu bạn không cung cấp cho chúng tôi thông tin cá nhân khi được yêu cầu, chúng tôi có thể không:",
  privacyPolicyP29: "xem xét đơn đăng ký của bạn để tham gia với chúng tôi với tư cách là nhân viên",
  privacyPolicyP30:
    "xem xét đơn đăng ký của bạn để trở thành nhà đầu tư, khách hàng, đối tác; nhà cung cấp hoặc nhà cung cấp dịch vụ; Và",
  privacyPolicyP31: "trả lời các truy vấn hoặc yêu cầu thông tin của bạn.",
  privacyPolicyP32:
    "Cookie trình duyệt là một phần nhỏ dữ liệu được lưu trữ trên thiết bị của bạn để giúp các trang web và ứng dụng di động ghi nhớ những điều về bạn. Mặc dù chúng tôi không sử dụng cookie trong ứng dụng di động Laava, chúng tôi sử dụng cookie trên trang web của mình. Cookie giúp chúng tôi xem phần nào của trang web của chúng tôi là phổ biến nhất, cho phép chúng tôi tập trung vào việc cải thiện những khía cạnh của trang web sẽ cải thiện trải nghiệm của bạn và cung cấp cho bạn trải nghiệm tốt hơn, trực quan hơn và thỏa mãn hơn. Trình duyệt hoặc thiết bị của bạn có thể cho phép bạn chặn hoặc hạn chế sử dụng cookie. Bạn cũng có thể xóa cookie khỏi trình duyệt của mình.",
  privacyPolicyP33:
    "Để giúp cải thiện dịch vụ chúng tôi cung cấp thông qua trang web của chúng tôi, một số thông tin nhất định có thể được thu thập từ trình duyệt của bạn. Thông tin này được thu thập dưới dạng thông tin thống kê và bao gồm địa chỉ IP, loại trình duyệt, ngôn ngữ và thời gian truy cập. Trong nhiều trường hợp, các công cụ được sử dụng trên trang web của chúng tôi ghi lại thông tin theo cách không xác định bạn là một cá nhân. Trong các trường hợp khác, thông tin có thể là thông tin cá nhân trong trường hợp chính sách bảo mật này sẽ được áp dụng.",
  privacyPolicyP34:
    "Trang web của chúng tôi cũng có thể sử dụng các công cụ trang web của bên thứ ba theo thời gian, ví dụ: Google Analytics và các công cụ khảo sát trực tuyến. Để biết thêm thông tin về cách Google thu thập và xử lý dữ liệu, vui lòng xem chính sách quyền riêng tư của Google và thông tin của họ tại: ({{link}}).",
  privacyPolicyP35:
    "Chúng tôi thực hiện tất cả các bước hợp lý để giữ cho thông tin cá nhân được bảo vệ khỏi mất mát, can thiệp, lạm dụng hoặc truy cập, sửa đổi hoặc tiết lộ trái phép. Các bước này có thể bao gồm kiểm soát truy cập, mã hóa và cơ sở bảo mật. Chúng tôi lưu trữ thông tin cá nhân ở cả dạng vật lý và điện tử, đôi khi với sự hỗ trợ của các bên thứ ba như nhà cung cấp dịch vụ lưu trữ dữ liệu.",
  privacyPolicyP36:
    "Theo thời gian, nếu bạn đã chọn nhận thông tin tiếp thị từ chúng tôi, chúng tôi có thể gửi cho bạn thông tin tiếp thị trực tiếp liên quan đến các dịch vụ của chúng tôi.",
  privacyPolicyP37:
    "Chúng tôi có thể liên hệ với bạn qua tin nhắn điện tử (ví dụ: email), trực tuyến (ví dụ: thông qua trang web của chúng tôi), qua thư và bằng các phương tiện khác, trừ khi bạn sau đó từ chối hoặc chúng tôi phải tuân theo các hạn chế pháp lý. Bạn có thể chọn không tham gia truyền thông tiếp thị, bằng cách liên hệ với chúng tôi bằng cách sử dụng các chi tiết liên hệ bên dưới.",
  privacyPolicyP38:
    "Tính chính xác của thông tin cá nhân chúng tôi nắm giữ và sử dụng là rất quan trọng đối với chúng tôi. Chúng tôi thực hiện các bước hợp lý để đảm bảo rằng thông tin cá nhân chúng tôi xử lý là chính xác, đầy đủ và cập nhật. Để giúp chúng tôi giữ thông tin cá nhân của bạn chính xác, vui lòng cho chúng tôi biết nếu có bất kỳ lỗi hoặc thay đổi nào trong thông tin cá nhân của bạn.",
  privacyPolicyP39:
    "Bạn có thể yêu cầu quyền truy cập và chỉnh sửa thông tin cá nhân mà chúng tôi nắm giữ về bạn bất cứ lúc nào bằng cách liên hệ với Nhân viên Bảo mật của chúng tôi thông qua các chi tiết liên hệ ở cuối Chính sách bảo mật này. Trong hầu hết các trường hợp, chúng tôi có thể giúp bạn kịp thời và không chính thức với các yêu cầu này. Trong các trường hợp khác, chúng tôi có thể cần xác minh danh tính của bạn và yêu cầu bạn thực hiện yêu cầu của bạn bằng văn bản.",
  privacyPolicyP40:
    "Bạn có thể liên hệ với Nhân viên Bảo mật của chúng tôi bằng cách sử dụng các chi tiết liên lạc ở cuối Chính sách quyền riêng tư này nếu bạn có bất kỳ lo ngại nào về cách chúng tôi xử lý thông tin cá nhân của bạn. Chúng tôi sẽ trả lời để cho bạn biết ai sẽ xử lý vấn đề của bạn và khi nào bạn có thể mong đợi phản hồi thêm. Chúng tôi có thể yêu cầu thêm chi tiết từ bạn về mối quan tâm của bạn và chúng tôi có thể cần tham gia hoặc tham khảo ý kiến với các bên khác để điều tra và giải quyết vấn đề của bạn. Chúng tôi sẽ lưu giữ hồ sơ về yêu cầu của bạn và bất kỳ giải pháp nào. Nếu bạn không hài lòng với cách chúng tôi giải quyết khiếu nại của bạn, bạn có thể liên hệ với Văn phòng Ủy viên Thông tin Úc.",
  privacyPolicyP41:
    "Nếu bạn có bất kỳ câu hỏi, khiếu nại hoặc khiếu nại nào liên quan đến Máy quét trang web hoặc các Điều khoản và Điều kiện này, bạn có thể liên hệ với Laava tại:",
  problemsTitle: "Sự cố với dấu vân tay",
  problemsIntro:
    "Nếu Dấu vân tay thông minh Laava™ xuất hiện bị hỏng hoặc bạn gặp sự cố khi quét, bạn có thể liên hệ và chúng tôi sẽ cố gắng hết sức để giúp bạn giải quyết vấn đề.",
  tryScanningAgain: "Thử quét lại",
  emailUs: "Gửi email cho chúng tôi",
  troubleDetectingFingerprint: "Chúng tôi gặp sự cố khi phát hiện dấu vân tay",
  makeSureFingerprint: "Đảm bảo dấu vân tay được chiếu sáng tốt và được lấy nét",
  ensureInternet: "Đảm bảo kết nối internet của bạn tốt",
  tryAgain: "Thử lại",
  useLegacyScanner: "Sử dụng scanner cũ",
  logIn: "Đăng nhập",
  account: "Tài khoản",
  emailAddress: "Địa chỉ email",
  password: "Mật khẩu",
  submit: "Gửi",
  thereWasAProblemLoggingIn: "Đã có sự cố khi đăng nhập. Vui lòng thử lại.",
  loggedInAs: "Đăng nhập với tư cách là",
  logOut: "Đăng xuất"
}

export default vi
